import { Link } from 'react-router-dom'
import React from 'react'
import line from '../../assets/images/anim_line.png'
import blueapp from '../../assets/images/appstore_blue.png'
import blue from '../../assets/images/googleplay_blue.png'
import logo from '../../assets/images/logo1.png'
import top from '../../assets/images/go_top.png'
// import Goone from "../../assets/images/go_top-one.png"
// import Section from '../../assets/images/section-bg.png'
// import Shape1 from '../../assets/images/banner-shape-one1.png'
// import Shape2 from "../../assets/images/banner-shape2two.png"
// import Shape3 from "../../assets/images/banner-shapethree3.png"
// import Footerimg from "../../assets/images/footer_logo-one.png"
// import Banner1 from '../../assets/images/banner-shape1.png'
// import Banner2 from '../../assets/images/banner-shape2.png'

const Main = ({ footer }) => {

    return (
        <>
            <section className="newsletter_section">
                <div className="container">
                    <div className="newsletter_box">
                        <div className="section_title">
                            <h2>Subscribe newsletter</h2>
                            <p>Be the first to recieve all latest post in your inbox</p>
                        </div>
                        <form action="" >
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="form-group">
                                <button className="btn">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {footer.f1 &&
                <footer>
                    <div className="top_footer" id="contact">
                        <div className="anim_line dark_bg">
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                            <span><img src={line} alt="anim_line" /></span>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="abt_side">
                                        <div className="logo"> <img src={logo} alt="image" /></div>
                                        <ul>
                                            <li><Link to="#">support@misoulmate.com</Link></li>
                                            <li><Link to="#">+1-289-639-1765</Link></li>
                                        </ul>
                                        <ul className="social_media">
                                            <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                            <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3></h3>
                                        {/* <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About us</Link></li>
                                            <li><Link to="#">Services</Link></li>
                                            <li><Link to="/blog">Blog</Link></li>
                                            <li><Link to="/contact">Contact us</Link></li>
                                        </ul> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3>Help & Suport</h3>
                                        <ul>
                                            <li><Link to="/faq">FAQs</Link></li>
                                            <li><Link to="/contact">Contact us</Link></li>
                                            {/* <li><Link to="/work">How it works</Link></li> */}
                                            <li><Link to="/terms-of-service">Terms & conditions</Link></li>
                                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                            <li><Link to="/cookie-policy">Cookie policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-12">
                                    <div className="try_out">
                                        <h3>Let’s Try Out</h3>
                                        <ul className="app_btn">
                                            <li>
                                                <a href="https://apps.apple.com/us/app/misoulmate-muslim-marriage/id6596806129?itscg=30200&itsct=apps_box_link&mttnsubad=6596806129" target="_blank" rel="noopener noreferrer">
                                                        <img src={blueapp} alt="App Store" />
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <img src={blue} alt="image" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom_footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>© MiSoulmate Inc. 2024. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="go_top">
                        <span><img src={top} alt="image" /></span>
                    </div>
                </footer>
            }


        </>
    )
}

export default Main