import { Link } from 'react-router-dom'
import React, {useState} from 'react'
import img from '../../../assets/images/anim_line.png'
import blueapp from '../../../assets/images/appstore_blue.png'
import blue from '../../../assets/images/googleplay_blue.png'
import screen from '../../../assets/images/PreLoginView.png'
import screen1 from '../../../assets/images/LoginView.png'


const Main = () => {
    const [animate, setanimate] = useState()
   
    const [purple, setpurple] = useState()

    window.addEventListener('scroll', function() {
        const element = document.getElementsByClassName('free_text');
        const position = element[0].getBoundingClientRect();
        
        if(position.top < window.innerHeight && position.bottom >= 0) {
            const elm = document.getElementsByClassName("purple_backdrop");
            elm[0].style.opacity = "1"; 
        }else{
            const elm = document.getElementsByClassName("purple_backdrop");
            elm[0].style.opacity = "0"; 

        }
    });

  return (
    <>
        <section className="row_am free_app_section review_freeapp" id="getstarted">
    <div className="container">
        <div className={`free_app_inner aos-init ${animate ? "aos-animate" : ""}`} data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100"> 
            <div className="anim_line dark_bg">
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
                <span><img src={img} alt="anim_line" /></span>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="free_text">
                        <div className="section_title">
                            <h2>Let’s download free from apple and play store</h2>
                            <p>Download the MiSoulmate app for free on the App Store and Google Play. Connect and find your match effortlessly!</p>
                        </div>
                        <ul className="app_btn">
                            <li>
                                <a href="https://apps.apple.com/us/app/misoulmate-muslim-marriage/id6596806129?itscg=30200&itsct=apps_box_link&mttnsubad=6596806129" target="_blank" rel="noopener noreferrer">
                
                                     <img src={blueapp} alt="App Store" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src={blue} alt="Google Play Store" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="free_img">
                        <img src={screen} alt="Device Image" />
                        <img className="mobile_mockup" src={screen1} alt="Mobile Mockup" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

        <div className="purple_backdrop"></div>
    </>
  )
}

export default Main