import React from 'react'

function Map() {
    return (
        <>
            <section className="row_am map_section">
                <div className="container">
                    <div className="map_inner">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11592.837242601866!2d-79.869660!3d43.253740!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b8f57d28b0d%3A0x58169db6fa6e579f!2s1%20Hunter%20St%20E%2C%20Hamilton%2C%20ON%20L8N%203W1%2C%20Canada!5e0!3m2!1sen!2sus!4v1694173452005!5m2!1sen!2sus"
                            width="100%" 
                            height="510" 
                            style={{ border: "0" }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Map;
