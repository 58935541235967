import React from 'react';

function Review() {
    return (
        <>
            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="section_title">
                            <h2>Privacy Policy</h2>
                        </div>
                        <div className="info">
                            {/* <h3>MiSoulmate Privacy Policy</h3> */}
                            <p>
                                This Privacy Policy applies to MiSoulmate Inc., our website at www.misoulmate.com, and our iOS and Android mobile applications (collectively our “Platform”).
                            </p>
                            <p>
                                In this Privacy Policy, we inform you about the scope of the processing of your personal data, how it is stored, and your rights.
                            </p>
                            <p>
                                This policy is in line with:
                            </p>
                            <ul>
                                <li>Canada’s Personal Data Protection and Electronic Documents Act (PIPEDA)</li>
                                <li>Delaware’s Online Privacy and Protection Act (DOPPA)</li>
                                <li>California’s Consumer Privacy Act (CCPA) and amendments from the California Privacy Rights and Enforcement Act (CPRA)</li>
                                <li>EU’s General Data Protection Regulation (GDPR)</li>
                            </ul>

                            <h3>General Information</h3>
                            <p><strong>What is Personal Data?</strong></p>
                            <p>
                                Personal Data refers to any information that identifies or can identify an individual. This includes information such as your name, email address, phone number, or online identifiers like your IP address.
                            </p>

                            <p><strong>What is Special Category Data?</strong></p>
                            <p>
                                Special Category Data includes more sensitive personal information, such as details about religious beliefs, health, sexual orientation, and racial or ethnic origin. We only process Special Category Data with your explicit consent.
                            </p>

                            <p><strong>What is Processing?</strong></p>
                            <p>
                                "Processing" refers to any operation performed on personal data, such as collection, storage, use, or deletion.
                            </p>

                            <h3>Responsible for Data Processing</h3>
                            <p>
                                The data controller responsible for processing is MiSoulmate Inc., based in Ontario, Canada. For questions related to your personal data, please contact us at support@misoulmate.com.
                            </p>

                            <h3>How We Process Personal Data</h3>
                            <p>
                                We process personal data to provide our services efficiently and securely. Our processing operations are based on legal grounds, including your consent, the necessity for contract performance, or our legitimate business interests.
                            </p>
                            <p>
                                We collect, store, and process data necessary to operate our app. This includes:
                            </p>
                            <ul>
                                <li>Master Data: Names, gender, images, preferences, and other user-provided information.</li>
                                <li>Contact Information: Phone numbers, email addresses.</li>
                                <li>App Usage Data: App interaction times, features accessed.</li>
                                <li>Connection Data: Device information, location data.</li>
                            </ul>
                            <p>
                                We only process personal data for the necessary purposes (e.g., enabling your profile, matching with other users) and delete or anonymize data once it’s no longer needed or legally required.
                            </p>

                            <h3>Data Storage & Security</h3>
                            <p>
                                We take the protection of your data seriously and employ robust security measures to ensure its confidentiality, integrity, and availability. Your personal data is stored securely on Amazon Web Services (AWS) servers, with additional storage in Amazon S3 for user-uploaded images and profile photos.
                            </p>
                            <p>
                                We use AWS Amplify for authentication, and your passwords are encrypted to ensure they are stored securely. We follow best practices for securing data at rest and in transit.
                            </p>

                            <h3>How We Handle Your Data</h3>
                            <p><strong>Amazon S3 & Data Storage</strong></p>
                            <p>
                                We use Amazon S3 to store your images and profile information. Your data is encrypted both in transit and at rest using AWS encryption technologies. We use a strong access control mechanism to ensure only authorized parties can access your data.
                            </p>

                            <p><strong>Amazon Chime SDK for Video Calls</strong></p>
                            <p>
                                Our live video matching feature utilizes Amazon Chime SDK. While we facilitate live video calls for real-time interactions, we do not store the video or audio content from these meetings. The only information we save is the Meeting ID, which is stored in our DynamoDB database to record interactions, but no video content is retained once the call ends.
                            </p>

                            <p><strong>DynamoDB & User Information</strong></p>
                            <p>
                                User data, including your profile, preferences, and messages, are securely stored in AWS DynamoDB. Passwords and other sensitive information are handled by AWS Amplify, ensuring they are securely encrypted.
                            </p>

                            <h3>How We Protect Your Data</h3>
                            <p>
                                We implement a wide range of technical and organizational measures to ensure the security of your data. These include:
                            </p>
                            <ul>
                                <li>Encryption: All data is encrypted during transmission and storage using SSL/TLS protocols.</li>
                                <li>Access Control: We enforce strict access controls to ensure only authorized personnel can access personal data.</li>
                                <li>Data Pseudonymization: Where possible, we pseudonymize personal data to protect user identities.</li>
                                <li>Data Retention: Your data is retained only as long as necessary for the purposes outlined in this Privacy Policy. We regularly review and securely delete data no longer needed.</li>
                            </ul>

                            <h3>Third-Party Sharing and International Data Transfer</h3>
                            <p>
                                We do not sell your personal data. However, we may share personal data with trusted third parties who help us provide our services, such as cloud service providers like AWS. These partners are contractually obligated to comply with stringent data protection laws, including PIPEDA and GDPR.
                            </p>
                            <p>
                                While we may transfer your data internationally (for instance, to AWS servers located outside Canada), we ensure that appropriate safeguards, such as Standard Contractual Clauses (SCCs), are in place to protect your data during transfer.
                            </p>

                            <h3>Data Privacy in Ontario</h3>
                            <p>
                                Under Canadian law (PIPEDA), you have the following rights concerning your data:
                            </p>
                            <ul>
                                <li>Right to Access: You can request a copy of your data.</li>
                                <li>Right to Correction: You can request that we correct any inaccuracies in your data.</li>
                                <li>Right to Erasure: You can request the deletion of your data at any time by navigating to Profile -> Account Settings -> Delete Account.</li>
                                <li>Right to Withdraw Consent: You can withdraw your consent for us to process your data at any time.</li>
                            </ul>

                            <h3>How We Use Cookies</h3>
                            <p>
                                We use cookies to improve your experience on our website and app. Cookies are small pieces of data stored on your device that help us track user activity and preferences. For more information on the cookies we use, please refer to our Cookie Policy.
                            </p>

                            <h3>Account Registration and Deactivation</h3>
                            <p>
                                To use MiSoulmate, you are required to create an account. You can delete or deactivate your account at any time by navigating to Profile -> Account Settings -> Delete Account. Once your account is deleted, all associated data, including your profile, matches, and messages, will be removed.
                            </p>

                            <h3>Marketing and Notifications</h3>
                            <p>
                                By registering on MiSoulmate, you consent to receiving notifications, including updates about new features, match suggestions, and administrative messages. You can opt-out of these communications by adjusting your preferences in the app or via device settings.
                            </p>

                            <h3>Updates to This Privacy Policy</h3>
                            <p>
                                We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. If we make significant changes, we will notify you via email or through the app.
                            </p>

                            <h3>Contact Us</h3>
                            <p>
                                For any questions or concerns regarding your privacy or data security, please contact us at support@misoulmate.com.
                            </p>
                            <p><strong>Effective Date:</strong> February 7, 2024</p>
                        </div>

                        <div className="quote_block">
                            <h2>"Your privacy and security are our priority, and we comply with all local and international laws to protect your data."</h2>
                            <p><span className="name">MiSoulmate Inc.,</span> Data Protection Team</p>
                        </div>

                        {/* <div className="blog_authore">
                            <div className="authore_info">
                                <div className="text">
                                    <h3>By: MiSoulmate Data Team</h3>
                                    <span>Updated: February 7, 2024</span>
                                </div>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>privacy,</span></li>
                                <li><span>security,</span></li>
                                <li><span>data protection</span></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Review;
