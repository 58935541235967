import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/sunni_marriage_ceremony.png"; // Updated unique image

function HowMarriageCeremonyIsConducted() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>How is the Marriage Ceremony Conducted in Sunni Tradition? | Islamic Insights</title>
                <meta name="description" content="Discover the step-by-step process of a Sunni marriage ceremony, its religious significance, and traditional customs that make it unique." />
                <meta name="keywords" content="Sunni marriage ceremony, Islamic wedding, Nikah, Muslim matrimonial traditions, Islamic marriage customs" />
                <meta property="og:title" content="How is the Marriage Ceremony Conducted in Sunni Tradition? | Islamic Insights" />
                <meta property="og:description" content="Explore the beautiful rituals of the Sunni marriage ceremony, from Nikah to Walima, and understand their importance in Islamic culture." />
                <meta property="og:image" content="/path/to/sunni_marriage_ceremony.png" />
                <meta property="og:url" content="https://misoulmate.com/how-is-the-marriage-ceremony-conducted-in-sunni-tradition" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="Sunni Marriage Ceremony" 
                paragraph="Understand the customs of a Sunni wedding." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 4, 2024</span>
                        </div>
                        <div className="section_title">
                            <h2>How is the Marriage Ceremony Conducted in Sunni Tradition?</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Sunni Marriage Ceremony" />
                        </div>
                        <div className="info">
                            <p>The Sunni marriage ceremony, known as the Nikah, is a sacred bond that unites two individuals under the guidance of Islamic principles. It is not merely a contractual agreement but a profound spiritual partnership that is integral to the fabric of Islamic society.</p>

                            <h3>The Proposal (Ijab-O-Qubul)</h3>
                            <p>The marriage process begins with the proposal, where the groom or his family approaches the bride’s family. If both families agree, the groom formally proposes to the bride. This proposal is known as 'Ijab'. The bride’s acceptance is called 'Qubul'. This mutual consent is the cornerstone of the Nikah.</p>

                            <h3>Role of the Wali (Guardian)</h3>
                            <p>The presence of a Wali (guardian) is essential for the bride. The Wali ensures that the bride is entering the marriage willingly and that her rights are safeguarded. Typically, the Wali is the bride’s father or a close male relative.</p>

                            <h3>Witnesses in Nikah</h3>
                            <p>Two male witnesses or one male and two female witnesses must be present to validate the Nikah. Their role is to observe and confirm the agreement between the bride and groom.</p>

                            <h3>The Mahr (Dowry)</h3>
                            <p>Mahr is a mandatory gift from the groom to the bride, symbolizing his commitment and responsibility. It can be a monetary amount, jewelry, or anything agreed upon by both parties.</p>

                            <h3>Recitation of Nikah Khutbah</h3>
                            <p>The Nikah ceremony often includes a Khutbah (sermon) delivered by an Islamic scholar or Imam. This sermon typically includes verses from the Quran and Hadiths about the virtues of marriage and the duties of spouses.</p>

                            <h3>Signing the Nikah Nama</h3>
                            <p>After the Ijab-o-Qubul and Mahr agreement, the bride, groom, and witnesses sign the Nikah Nama (marriage contract). This document legally binds the couple under Islamic law.</p>

                            <h3>Walima (Wedding Feast)</h3>
                            <p>The Walima, a wedding feast hosted by the groom, is a celebratory event that marks the public declaration of the marriage. It is a Sunnah (tradition) of the Prophet Muhammad (PBUH) and serves as a joyous occasion for family and friends to gather.</p>

                            <h3>Conclusion</h3>
                            <p>The Sunni marriage ceremony is a harmonious blend of religious obligations and cultural traditions. It emphasizes mutual respect, responsibility, and the pursuit of a shared spiritual journey. Platforms like <strong>MiSoulmate: Muslim Marriage App</strong> provide modern Muslims with a pathway to meet compatible partners, ensuring a sincere and serious approach to finding a life partner. MiSoulmate’s unique 10-minute live matching session helps users gauge compatibility, reducing the risks of fake profiles and ghosting. This innovation aligns perfectly with the principles of Islamic marriage, focusing on authenticity and mutual understanding.</p>

                            <div className="quote_block">
                                <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                                <h2>"Marriage is part of my Sunnah, and whoever does not follow my Sunnah has nothing to do with me."</h2>
                                <p><span className="name">Prophet Muhammad (PBUH),</span> Sahih Bukhari</p>
                            </div>

                            <p>In essence, the Sunni marriage ceremony not only unites two individuals but also fosters a community built on strong moral and spiritual foundations. By adhering to these timeless traditions, Muslims can ensure that their unions are blessed, meaningful, and aligned with the teachings of Islam.</p>
                        </div>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 4, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Marriage,</span></li>
                                <li><span>Islam,</span></li>
                                <li><span>Sunni Tradition</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowMarriageCeremonyIsConducted;
