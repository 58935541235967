import React from 'react';
import { Link } from 'react-router-dom';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/islamic_nikah_illustration.png"; // New image for the article

function IslamicNikah() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Understanding Islamic Nikah | The Sacred Marriage</title>
                <meta name="description" content="Explore the significance of Nikah in Islam, the principles behind it, and the steps involved in this sacred marital contract." />
                <meta name="keywords" content="Islamic Nikah, Muslim Marriage, Nikah Ceremony, Islamic Marriage, Marriage in Islam" />
                <meta property="og:title" content="Understanding Islamic Nikah | The Sacred Marriage" />
                <meta property="og:description" content="Discover the essence of Nikah in Islam, its spiritual and social importance, and the process of entering this sacred contract." />
                <meta property="og:image" content="/path/to/islamic_nikah_share_image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/islamic-nikah" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                <div className="container">
                    <div className="bred_text">
                        <h1>Blog</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span>»</span></li>
                            <li><Link to="/blog">Blogs</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>October 22, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>Understanding Islamic Nikah</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Understanding Islamic Nikah" />
                        </div>
                        <div className="info">
                            <p>Nikah, or marriage in Islam, is a significant institution that encompasses both a legal contract and a spiritual bond between a man and a woman. This sacred union serves as a foundation for family life and fulfills the moral and social responsibilities of individuals within the Islamic framework. As Prophet Muhammad (PBUH) stated, "Marriage is part of my Sunnah. Whosoever keeps away from it is not of me" (Sunan Ibn Majah). This hadith emphasizes the importance of Nikah as a vital component of a Muslim’s life.</p>

                            <p>The concept of Nikah transcends mere companionship. It embodies the principles of mutual respect, love, and commitment. This article delves into the significance of Nikah in Islam, the rights and responsibilities associated with it, and the steps to solemnize this sacred contract.</p>

                            <h3>The Significance of Nikah in Islam</h3>
                            <p>Nikah is more than just a social contract; it is a sacred commitment that fulfills spiritual, emotional, and social needs. It is a means of safeguarding chastity and preserving morality within the community. The Quran states, "And marry those among you who are single, and those who are fit among your male and female slaves. If they are poor, Allah will enrich them from His bounty" (Quran 24:32). This verse highlights the encouragement of marriage to promote social harmony and reduce immorality.</p>

                            <p>Marriage is also a source of tranquility, as indicated in the Quran: "And among His signs is this, that He created for you mates from among yourselves, that you may dwell in tranquility with them, and He has put love and mercy between your hearts" (Quran 30:21). Through Nikah, spouses find peace, companionship, and emotional support, essential for a fulfilling life.</p>

                            <h3>The Rights and Responsibilities in Nikah</h3>
                            <p>Islam outlines clear rights and responsibilities for both husband and wife within the marriage contract. The Quran states, "And they (wives) have rights similar to those (husbands) over them in kindness" (Quran 2:228). This emphasizes the importance of mutual respect and understanding in the relationship.</p>

                            <p>The husband is generally regarded as the protector and provider of the family, while the wife plays a crucial role in nurturing the household and upbringing of children. Both partners are expected to support each other emotionally, spiritually, and physically. The concept of consultation (shura) is vital in Islamic marriages, where both partners engage in decision-making processes together.</p>

                            <h3>Steps to Conducting Nikah</h3>
                            <p>The Nikah ceremony is a significant event that typically involves several key steps:</p>
                            <ol>
                                <li><strong>Proposal (Ijab):</strong> The husband-to-be makes a formal proposal to the bride's family, expressing his intention to marry their daughter.</li>
                                <li><strong>Acceptance (Qabul):</strong> The bride's family accepts the proposal, and the bride herself must also give her consent. Her agreement is crucial for the marriage to proceed.</li>
                                <li><strong>Witnesses:</strong> At least two witnesses, preferably Muslim men, must be present during the ceremony to validate the marriage.</li>
                                <li><strong>Mahr (Dowry):</strong> The groom is required to provide a predetermined amount of Mahr (dowry) to the bride, which is her right in the marriage.</li>
                                <li><strong>Nikah Khutbah:</strong> A sermon or address by an imam or scholar, explaining the significance of marriage and the rights of each partner. This is often followed by prayers for blessings.</li>
                                <li><strong>Signing of the Nikah Nama:</strong> The Nikah Nama (marriage contract) is signed by both parties and the witnesses, formalizing the union.</li>
                            </ol>

                            <h3>The Spiritual Aspect of Nikah</h3>
                            <p>Nikah is viewed as an act of worship in Islam, where both spouses pledge to support each other in their spiritual journeys. The marriage contract includes a commitment to uphold the principles of faith, morality, and mutual respect.</p>

                            <h3>The Importance of Communication in Nikah</h3>
                            <p>Effective communication is crucial for a successful marriage. In Islam, couples are encouraged to engage in open discussions about their needs, expectations, and any challenges they may face.</p>

                            <h3>Challenges in Nikah</h3>
                            <p>Like any relationship, Nikah may face challenges. Conflicts can arise due to differences in opinions, financial pressures, or external influences. However, Islam encourages couples to address these issues with patience and understanding.</p>

                            <h3>The Role of Family in Nikah</h3>
                            <p>Family plays a pivotal role in the Nikah process. The support and involvement of both families contribute to a successful marriage.</p>

                            <h3>The Joys of Nikah</h3>
                            <p>Nikah offers numerous joys and rewards. The companionship and love between spouses create a nurturing environment for both partners and their future children.</p>

                            <h3>Final Thoughts</h3>
                            <p>Nikah in Islam is a sacred institution. By understanding the significance of this sacred contract and the rights and responsibilities that come with it, couples can build strong foundations for a lasting and fulfilling marriage.</p>

                            <div className="author_info">
                                <img src={BlogD1} alt="Ayesha Khan" />
                                <div className="author_details">
                                    <h4>Ayesha Khan</h4>
                                    <p>Islamic Scholar and Relationship Advisor</p>
                                </div>
                            </div>

                            {/* Call to Action */}
                            <div className="call_to_action">
                                <h3>Ready to Start Your Journey?</h3>
                                <p>If you are considering marriage or have questions about the Nikah process, don’t hesitate to reach out for guidance.</p>
                                <Link to="/contact" className="btn btn-primary">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default IslamicNikah;
