import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import img1 from '../../../assets/images/standard.png'
import img2 from '../../../assets/images/unlimited.png'
import img3 from '../../../assets/images/premium.png'
import Starded1 from "../../../assets/images/standard-one.png"
import Unlimited1 from "../../../assets/images/unlimited-one.png"
import Premium1 from "../../../assets/images/premium-one.png"

const Main = ({ gredient, video }) => {

    const [tog, setTog] = useState(false) // Tog to switch between Monthly and Yearly plans

    return (
        <>
            {gredient ?
                <section className="row_am pricing_section gredient-pricing" id="pricing">
                    <div className="container">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                            <h2>Best & simple <span>pricing</span></h2>
                            <p className="gredient-p">Unlock your potential with our premium features.</p>
                        </div>
                        <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                            <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
                            <div className="tog_block">
                                <span className={`tog_btn ${tog && "month_active"}`} onClick={() => setTog(!tog)}></span>
                            </div>
                            <span className="years">6 Months</span>
                            <span className="offer">Save 58%</span>
                        </div>

                        <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="pricing_block">
                                        <div className="icon">
                                            <img src={Starded1} alt="image" />
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Standard</h3>
                                            <span>Basic Features</span>
                                        </div>
                                        <span className="price">$9.99</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>35 More Swipes Per Day</p>
                                            </li>
                                            <li>
                                                <p>5 Live Matches Per Day</p>
                                            </li>
                                            <li>
                                                <p>Direct Messaging</p>
                                            </li>
                                            <li>
                                                <p>24x Views on Your Profile</p>
                                            </li>
                                        </ul>
                                        <Link to="/faq" className="btn white_btn">SUBSCRIBE NOW</Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="pricing_block highlited_block">
                                        <div className="icon">
                                            <img src={Unlimited1} alt="image" />
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Unlimited</h3>
                                            <span>For Premium Users</span>
                                        </div>
                                        <span className="price">$24.99</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>35 More Swipes Per Day</p>
                                            </li>
                                            <li>
                                                <p>5 Live Matches Per Day</p>
                                            </li>
                                            <li>
                                                <p>Direct Messaging</p>
                                            </li>
                                            <li>
                                                <p>24x Views on Your Profile</p>
                                            </li>
                                        </ul>
                                        <Link to="/faq" className="btn white_btn">SUBSCRIBE NOW</Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="pricing_block">
                                        <div className="icon">
                                            <img src={Premium1} alt="image" />
                                        </div>
                                        <div className="pkg_name">
                                            <h3>6-Month Plan</h3>
                                            <span>For serious users</span>
                                        </div>
                                        <span className="price">$99.99</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>35 More Swipes Per Day</p>
                                            </li>
                                            <li>
                                                <p>5 Live Matches Per Day</p>
                                            </li>
                                            <li>
                                                <p>Direct Messaging</p>
                                            </li>
                                            <li>
                                                <p>24x Views on Your Profile</p>
                                            </li>
                                        </ul>
                                        <Link to="/faq" className="btn white_btn">SUBSCRIBE NOW</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="contact_text" data-aos="fade-up" data-aos-duration="1500">Not sure what to choose? <Link to="/contact">contact us</Link> for more information</p>
                    </div>
                </section>
                :
                <section className="row_am pricing_section" id="pricing">
                    <div className="container">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                            <h2>Best & simple <span>pricing</span></h2>
                            <p>Choose the plan that suits you best.</p>
                        </div>
                        <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                            <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
                            <div className="tog_block">
                                <span className={`tog_btn ${tog && "month_active"}`} onClick={() => setTog(!tog)}></span>
                            </div>
                            <span className="years">6 Months</span>
                            <span className="offer">Save 58%</span>
                        </div>

                        <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="pricing_block">
                                        <div className="icon">
                                            <img src={img1} alt="image" />
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Standard</h3>
                                            <span>For Basic Users</span>
                                        </div>
                                        <span className="price">$9.99</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>35 More Swipes Per Day</p>
                                            </li>
                                            <li>
                                                <p>5 Live Matches Per Day</p>
                                            </li>
                                            <li>
                                                <p>Direct Messaging</p>
                                            </li>
                                            <li>
                                                <p>24x Views on Your Profile</p>
                                            </li>
                                        </ul>
                                        <Link to="/faq" className="btn white_btn">SUBSCRIBE NOW</Link>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="pricing_block highlited_block">
                                        <div className="icon">
                                            <img src={img2} alt="image" />
                                        </div>
                                        <div className="pkg_name">
                                            <h3>Unlimited</h3>
                                            <span>For Professionals</span>
                                        </div>
                                        <span className="price">$24.99</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>35 More Swipes Per Day</p>
                                            </li>
                                            <li>
                                                <p>5 Live Matches Per Day</p>
                                            </li>
                                            <li>
                                                <p>Direct Messaging</p>
                                            </li>
                                            <li>
                                                <p>24x Views on Your Profile</p>
                                            </li>
                                        </ul>
                                        <Link to="/faq" className="btn white_btn">SUBSCRIBE NOW</Link>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="pricing_block">
                                        <div className="icon">
                                            <img src={img3} alt="image" />
                                        </div>
                                        <div className="pkg_name">
                                            <h3>6-Month Plan</h3>
                                            <span>For Serious Users</span>
                                        </div>
                                        <span className="price">$99.99</span>
                                        <ul className="benifits">
                                            <li>
                                                <p>35 More Swipes Per Day</p>
                                            </li>
                                            <li>
                                                <p>5 Live Matches Per Day</p>
                                            </li>
                                            <li>
                                                <p>Direct Messaging</p>
                                            </li>
                                            <li>
                                                <p>24x Views on Your Profile</p>
                                            </li>
                                        </ul>
                                        <Link to="/faq" className="btn white_btn">SUBSCRIBE NOW</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default Main
