import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        {
            imgSrc: "/assets/images/islamic_marriage_contract_couple.png",
            time: "October 29th, 2024",
            title: "Islamic Marriage Contract",
            content: "Understanding the importance of contracts in Islamic marriages...",
            link: "/islamic-marriage-contract"
        },
        {
            imgSrc: "/assets/images/arabic_marriage_traditions.png",
            time: "October 29th, 2024",
            title: "Arabic Marriage Traditions",
            content: "Explore the traditions and customs of Arabic marriages...",
            link: "/arabic-marriage"
        },
        {
            imgSrc: "/assets/images/islamic_wedding_contract_image.png",
            time: "October 29th, 2024",
            title: "Understanding Islamic Wedding Contract",
            content: "Learn about the key components of the Islamic wedding contract...",
            link: "/understanding-islamic-wedding-contract"
        },
        {
            imgSrc: "/assets/images/islamic_nikkah_ceremony.png",
            time: "October 29th, 2024",
            title: "Islamic Nikkah Ceremony",
            content: "Discover the beauty and significance of the Nikkah ceremony in Islam...",
            link: "/islamic-nikkah"
        },
        {
            imgSrc: "/assets/images/islamic_wedding_rules.png",
            time: "October 29th, 2024",
            title: "Islamic Wedding Rules",
            content: "An overview of the fundamental rules of Islamic weddings...",
            link: "/islamic-wedding-rules"
        },
        {
            imgSrc: "/assets/images/islamic_marriage_ceremony_nikah.png",
            time: "October 29th, 2024",
            title: "Islamic Marriage Ceremony",
            content: "Detailed insight into the Islamic marriage ceremony...",
            link: "/islamic-marriage-ceremony-nikah"
        },
        {
            imgSrc: "/assets/images/islam_wedding_ceremony.png",
            time: "October 29th, 2024",
            title: "Islam Wedding Ceremony",
            content: "A comprehensive look into the traditions of the Islamic wedding ceremony...",
            link: "/islam-wedding-ceremony"
        },
        {
            imgSrc: "/assets/images/islam_religion_marriage.png",
            time: "October 29th, 2024",
            title: "Understanding Marriage in Islam",
            content: "How marriage plays a crucial role in the Islamic faith...",
            link: "/understanding-marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/islam_marriage_ceremony.png",
            time: "October 29th, 2024",
            title: "Islam Marriage Ceremony",
            content: "A closer look at the Islamic marriage ceremony and its practices...",
            link: "/islam-marriage-ceremony"
        },
        {
            imgSrc: "/assets/images/islam_and_cousin_marriage.png",
            time: "October 29th, 2024",
            title: "Islam and Cousin Marriage",
            content: "Exploring cousin marriages and their place in Islam...",
            link: "/islam-and-cousin-marriage"
        },
        {
            imgSrc: "/assets/images/marriage_according_to_islam.png",
            time: "October 29th, 2024",
            title: "Marriage According to Islam",
            content: "What does Islam say about marriage? Explore the guidelines...",
            link: "/marriage-according-to-islam"
        },
        {
            imgSrc: "/assets/images/marrying_a_christian_in_islam.png",
            time: "October 29th, 2024",
            title: "Marrying a Christian in Islam",
            content: "Understanding the Islamic perspective on marrying Christians...",
            link: "/marrying-a-christian-in-islam"
        },
        {
            imgSrc: "/assets/images/multiple_marriage_in_islam.png",
            time: "October 29th, 2024",
            title: "Multiple Marriages in Islam",
            content: "A look at the practice of multiple marriages in Islam...",
            link: "/multiple-marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/multiple_wives_in_islam.png",
            time: "October 29th, 2024",
            title: "Multiple Wives in Islam",
            content: "Discover the Islamic rules regarding having multiple wives...",
            link: "/multiple-wives-in-islam"
        },
        {
            imgSrc: "/assets/images/muslim_and_marriage.png",
            time: "October 29th, 2024",
            title: "Muslim and Marriage",
            content: "Explore the connection between Islam and marriage...",
            link: "/muslim-and-marriage"
        },
        {
            imgSrc: "/assets/images/cousin_marriage_in_islam.png",
            time: "October 29th, 2024",
            title: "Cousin Marriage in Islam",
            content: "An in-depth look at cousin marriages in Islam...",
            link: "/cousin-marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/muslim_can_marry_christian.png",
            time: "October 29th, 2024",
            title: "Muslims Can Marry Christians",
            content: "Islamic perspective on interfaith marriages...",
            link: "/muslims-can-marry-christians"
        },
        {
            imgSrc: "/assets/images/muslim_cousin_marriage.png",
            time: "October 29th, 2024",
            title: "Muslim Cousin Marriage",
            content: "Insights into cousin marriages within the Muslim community...",
            link: "/muslim-cousin-marriage"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_ceremony.png",
            time: "October 29th, 2024",
            title: "Muslim Marriage Ceremony",
            content: "Learn about the cultural significance of Muslim marriage ceremonies...",
            link: "/muslim-marriage-ceremony"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_contract.png",
            time: "October 29th, 2024",
            title: "Muslim Marriage Contract",
            content: "The role of contracts in Muslim marriages...",
            link: "/muslim-marriage-contract"
        },
        {
            imgSrc: "/assets/images/muslim_religion_marriage.png",
            time: "October 29th, 2024",
            title: "Muslim Religion and Marriage",
            content: "How marriage is intertwined with religion in Islam...",
            link: "/muslim-religion-marriage"
        },
        {
            imgSrc: "/assets/images/muslim_religion_marriage_rules.png",
            time: "October 29th, 2024",
            title: "Muslim Religion Marriage Rules",
            content: "Learn about the religious rules governing Muslim marriages...",
            link: "/muslim-religion-marriage-rules"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_rules.png",
            time: "October 29th, 2024",
            title: "Muslim Marriage Rules",
            content: "Detailed look at the rules governing Muslim marriages...",
            link: "/muslim-marriage-rules"
        },
        {
            imgSrc: "/assets/images/muslim_wedding_ceremony.png",
            time: "October 29th, 2024",
            title: "Muslim Wedding Ceremony",
            content: "A guide to Muslim wedding ceremonies and traditions...",
            link: "/muslim-wedding-ceremony"
        },
        {
            imgSrc: "/assets/images/traditional_islamic_wedding_ceremony.png",
            time: "October 29th, 2024",
            title: "Traditional Islamic Wedding",
            content: "Explore the rich traditions of a traditional Islamic wedding...",
            link: "/traditional-islamic-wedding"
        },
        {
            imgSrc: "/assets/images/muslim_matrimony_couple.png",
            time: "October 29th, 2024",
            title: "Muslim Matrimony",
            content: "The significance of matrimony in the Muslim world...",
            link: "/muslim-matrimony"
        },
        {
            imgSrc: "/assets/images/temporary_nikah_image.png",
            time: "October 29th, 2024",
            title: "Temporary Nikah in Islam",
            content: "An analysis of the practice of temporary Nikah in Islam...",
            link: "/temporary-nikah-in-islam"
        },
        {
            imgSrc: "/assets/images/islamic_dating_couple.png",
            time: "October 29th, 2024",
            title: "Islamic Dating",
            content: "How Islamic dating works within the confines of religious rules...",
            link: "/islamic-dating"
        },
        {
            imgSrc: "/assets/images/muslim_matrimonial_websites.png",
            time: "October 29th, 2024",
            title: "Muslim Matrimonial Websites",
            content: "Explore the top matrimonial websites for Muslims...",
            link: "/muslim-matrimonial-websites"
        },
        {
            imgSrc: "/assets/images/muslim_4_marriage.png",
            time: "October 29th, 2024",
            title: "Muslim 4 Marriage",
            content: "Learn how Muslims navigate marriage online...",
            link: "/muslim-4-marriage"
        },
        {
            imgSrc: "/assets/images/muslima_dating_culture.png",
            time: "October 29th, 2024",
            title: "Muslima Dating Culture",
            content: "An exploration of dating culture among Muslima women...",
            link: "/muslima-dating"
        },
        {
            imgSrc: "/assets/images/islamic_marriage_sites.png",
            time: "October 29th, 2024",
            title: "Islamic Marriage Sites",
            content: "A guide to Islamic marriage websites...",
            link: "/islamic-marriage-sites"
        },
        {
            imgSrc: "/assets/images/islamic_marriage_website.png",
            time: "October 29th, 2024",
            title: "Islamic Marriage Website",
            content: "Find out which Islamic marriage websites are best for you...",
            link: "/islamic-marriage-website"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_sites.png",
            time: "October 29th, 2024",
            title: "Exploring Muslim Marriage Sites",
            content: "Explore the various marriage sites available for Muslims...",
            link: "/exploring-muslim-marriage-sites"
        },
        {
            imgSrc: "/assets/images/muslim_dating_couple.png",
            time: "October 29th, 2024",
            title: "Muslim Dating",
            content: "A look at Muslim dating practices within religious boundaries...",
            link: "/muslim-dating"
        },
        {
            imgSrc: "/assets/images/best_muslim_dating_apps.png",
            time: "October 29th, 2024",
            title: "Best Muslim Dating Apps",
            content: "Discover the best dating apps for Muslims...",
            link: "/best-muslim-dating-apps"
        },
        {
            imgSrc: "/assets/images/marriage_in_islam.png",
            time: "October 29th, 2024",
            title: "Marriage in Islam",
            content: "A comprehensive guide to marriage in Islam...",
            link: "/marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/islamic_matrimonial_website.png",
            time: "October 29th, 2024",
            title: "Islamic Matrimonial Website",
            content: "The top matrimonial websites for Muslim singles...",
            link: "/islamic-matrimonial-website"
        },
        {
            imgSrc: "/assets/images/unhappy_marriage_islam.png",
            time: "October 29th, 2024",
            title: "Unhappy Marriage in Islam",
            content: "What does Islam say about unhappy marriages? Learn more...",
            link: "/unhappy-marriage-in-islam"
        },
        {
            imgSrc: "/assets/images/muslim_couple_wedding.png",
            time: "October 29th, 2024",
            title: "Muslim Couple Wedding",
            content: "A beautiful guide to Muslim wedding traditions...",
            link: "/muslim-couple-wedding"
        }
    ];

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title}   style={{ width: "410px", height: "290px" }} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2" className="active">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog3" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
