import React, { useEffect } from 'react'
import AOS from "aos";

import Features from '../HomeMain/Features/Main'

import Download from '../HomeMain/Download/Main'
import Sliderone from '../Menhome/Sliderone';
import { Helmet } from "react-helmet"; // For SEO

// import Work from '../HomeMain/Work/Main'
// import Faq from '../HomeMain/Faq/Main'
// import Blog from '../Blog/FeaturedBlogPost/Main'
// import Interface from '../HomeMain/Interface/Main'
// import Testimonial from '../HomeMain/Testimonial/Main'
// import Pricing from '../HomeMain/Pricing/Main'
// import Design from '../HomeMain/Design/Main'
// import AboutApp from '../HomeMain/AboutApp/Main'
// import Trusted from '../HomeMain/Trusted/Main'

const Main = ({ setfooter, setnavbar, setbrdcum }) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f1: true })
    setnavbar({ n2: true })
    setbrdcum({ b1: true })
    localStorage.setItem("navbar", "home")

  }, [])

  return (
    <>
      <Helmet>
        <title>MiSoulmate: Muslim Marriage App - Find Your Halal Match</title>
        <meta name="description" content="MiSoulmate is a Muslim marriage app that connects you with compatible partners through live matchmaking, profiles, and Islamic values. Find your soulmate today." />
        <meta name="keywords" content="Muslim Marriage App, Halal Dating, Islamic Marriage, Find Muslim Partner, MiSoulmate, Muslim Matchmaking, Halal Match, Muslim Matrimony" />
      </Helmet>


      <Sliderone />
      {/* <Trusted /> */}
      <Features />
      {/* <AboutApp /> */}
      {/* <Design /> */}
      {/* <Work /> */}
      {/* <Testimonial /> */}
      {/* <Pricing /> */}
      {/* <Faq /> */}
      {/* <Interface /> */}
      <Download />
      {/* <Story /> */}
    </>
  )
}

export default Main