import React from 'react';
import { Link } from 'react-router-dom';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/islamic_marriage_contract_couple.png"; // Updated image

function IslamicMarriageContract() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>The Islamic Marriage Contract: Essential Pillars and Rights | Islamic Insights</title>
                <meta name="description" content="Explore the importance of the Islamic marriage contract (Nikah) in securing rights, obligations, and mutual respect between spouses. Learn about the legal and spiritual aspects of this key institution in Islam." />
                <meta name="keywords" content="Islamic Marriage Contract, Nikah, Muslim Marriage, Rights in Islam, Marriage in Islam, Islamic Law, Marriage Rights, Islamic Wedding" />
                <meta property="og:title" content="The Islamic Marriage Contract: Essential Pillars and Rights | Islamic Insights" />
                <meta property="og:description" content="Delve into the meaning and significance of the Islamic marriage contract and discover how it protects the rights of both spouses, ensuring a harmonious and respectful union." />
                <meta property="og:image" content="/path/to/islamic_marriage_contract_couple.png" />
                <meta property="og:url" content="https://misoulmate.com/islamic-marriage-contract" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                <div className="container">
                    <div className="bred_text">
                        <h1>Blog</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span>�</span></li>
                            <li><Link to="/blog">Blogs</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Blog Content */}
            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>October 23, 2024</span>
                        </div>
                        <div className="section_title">
                            <h2>The Islamic Marriage Contract: Essential Pillars and Rights</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Islamic Marriage Contract Couple" />
                        </div>
                        <div className="info">
                            <p>The Islamic marriage contract, known as the **Nikah**, is a foundational pillar of a Muslim marriage, serving as both a legal and spiritual agreement between spouses. More than just a formality, it ensures that both the rights and responsibilities of the husband and wife are upheld in accordance with Islamic law (Shariah). Prophet Muhammad (PBUH) emphasized the importance of the Nikah, saying, "Marriage is my Sunnah, and whoever does not follow my Sunnah has nothing to do with me" (Sahih Bukhari). This teaching highlights how essential marriage and its contract are within Islam.</p>

                            <h3>Key Components of the Islamic Marriage Contract</h3>
                            <p>Every Islamic marriage contract must meet several essential requirements to be considered valid under Islamic law. These requirements ensure that both parties enter the marriage willingly and with mutual understanding.</p>

                            <ul>
                                <li><p><strong>Consent of Both Parties:</strong> The most critical element of the Nikah is the mutual consent of both the bride and the groom. In Islam, forced marriages are strictly prohibited. The Prophet (PBUH) said, "A previously married woman must not be married until she is consulted, and a virgin must not be married until her permission is sought" (Sahih Muslim).</p></li>
                                <li><p><strong>Mahr (Dowry):</strong> The Mahr is a gift from the groom to the bride, which must be agreed upon before the marriage. It symbolizes the groom's commitment and responsibility toward his wife. The Quran emphasizes the importance of this gift: "And give the women [upon marriage] their [bridal] gift graciously" (Quran 4:4).</p></li>
                                <li><p><strong>Presence of Witnesses:</strong> A Nikah must have at least two witnesses to validate the marriage. This ensures transparency and protects the rights of both parties. The Quran mentions the importance of witnesses in contracts: "And take witnesses when you conclude a contract" (Quran 65:2).</p></li>
                                <li><p><strong>Offer and Acceptance (Ijab and Qubul):</strong> The marriage contract is completed when the groom makes an offer, and the bride formally accepts. This exchange must be done clearly and voluntarily in front of witnesses, making the union official in the eyes of Allah.</p></li>
                            </ul>

                            <h3>Rights and Obligations in an Islamic Marriage</h3>
                            <p>The Nikah not only binds the couple in a legal sense but also sets forth the rights and duties of both spouses. Islam ensures that the relationship is based on respect, kindness, and mutual obligations. The Quran emphasizes this balance: "And they (women) have rights similar to those (of men) over them in kindness" (Quran 2:228).</p>

                            <p>For a successful marriage, both partners must strive to fulfill their obligations toward one another. These include:</p>

                            <ul>
                                <li><p><strong>The Husband�s Duties:</strong> The husband is primarily responsible for the financial support and protection of the family. He must ensure that his wife is well-cared for and treated with kindness. The Quran states, "Men are the protectors and maintainers of women, because Allah has given the one more (strength) than the other, and because they support them from their means" (Quran 4:34).</p></li>
                                <li><p><strong>The Wife�s Duties:</strong> While the husband provides financial support, the wife is entrusted with the home and the upbringing of the children. Her role is to create a nurturing environment based on Islamic values. However, her contributions go beyond the household, and Islam encourages spouses to consult each other in all matters.</p></li>
                            </ul>

                            <h3>Legal and Spiritual Aspects of the Marriage Contract</h3>
                            <p>Marriage in Islam is both a legal agreement and an act of worship. The Nikah serves to protect the rights of both spouses, ensuring fairness and justice in the relationship. The spiritual significance of the Nikah cannot be overstated. The Prophet (PBUH) referred to marriage as "half of one's faith," highlighting its role in safeguarding one's spirituality.</p>

                            <p>By entering into a marriage, both individuals commit to helping each other grow in their faith. The Quran beautifully describes this partnership: "They are clothing for you, and you are clothing for them" (Quran 2:187). This metaphor suggests that spouses protect, support, and complete one another in all aspects of life.</p>

                            <h3>The Importance of Mutual Respect and Consultation</h3>
                            <p>One of the most critical elements in a successful Islamic marriage is mutual respect and communication. Both husband and wife are expected to consult each other on important matters, fostering an atmosphere of cooperation and trust. The Quran encourages this, saying, "And consult them in the matter" (Quran 3:159).</p>

                            <p>Marriage in Islam is a partnership, where both parties are responsible for the happiness and well-being of the other. The Prophet (PBUH) said, "The best among you are those who are best to their wives" (Sunan Tirmidhi). This teaching underscores the importance of kindness and compassion in maintaining a healthy marriage.</p>

                            <ul>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> The Islamic marriage contract secures the rights of both spouses.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Consent and mutual respect are essential in a Nikah.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Mahr is a symbol of the groom�s commitment and responsibility.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> The Nikah serves as both a legal contract and a spiritual union in Islam.</p></li>
                            </ul>
                        </div>

                        <div className="quote_block">
                            <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                            <h2>"Marriage is half of one's faith."</h2>
                            <p><span className="name">Prophet Muhammad (PBUH)</span></p>
                        </div>

                        {/* Editor Profile */}
                        <div className="editor_profile">
                            <div className="img_holder">
                                <img src={BlogD1} alt="Editor Ayesha Khan" />
                            </div>
                            <div className="info_holder">
                                <h4>Ayesha Khan</h4>
                                <p>Islamic Scholar & Editor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default IslamicMarriageContract;
