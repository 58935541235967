import React from 'react'
import Bredcrumb from '../../Bredcrumb/Main'
import BGImg from "../../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../../assets/images/bread_crumb_bg_two.png"
import First from '../Menreview/First'
import Second from '../../Pages/Menreview/Second'

const Main = ({ brdcum }) => {

  return (
    <>
      {brdcum.b1 &&
        <Bredcrumb no={1} title="Reviews" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Reviews" bgimg={BGImg} />
      }

      {brdcum.b2 &&
        <Bredcrumb no={2} title="Reviews" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Reviews" bgimg={BGImg1} />
      }

      {brdcum.b5 &&
        <Bredcrumb no={5} title="Reviews" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Reviews" bgimg={BGImg} />
      }

      {brdcum.b3 &&
        <Bredcrumb no={3} title="Reviews" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Reviews" bgimg={BGImg2} />
      }

      {brdcum.b4 &&
        <Bredcrumb no={4} title="Reviews" paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting" tag="Reviews" bgimg={BGImg2} />
      }
      <First />
      <Second />

    </>
  )
}

export default Main