import React from 'react'

function First() {
    const reviews = [
        {
            rating: 5,
            title: 'App working smoothly fast',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Shayna John',
                company: 'Creative inc',
                avatar: 'assets/images/review_01.png'
            }
        },
        {
            rating: 5,
            title: 'UI and UX is excellence !',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Jolly Shie',
                company: 'CEO - abc agency',
                avatar: 'assets/images/review_02.png'
            }
        },
        {
            rating: 5,
            title: 'Service support on time.',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Steve Joe',
                company: 'Vybo Creative',
                avatar: 'assets/images/review_01.png'
            }
        },
        {
            rating: 5,
            title: 'Easy documentation.',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Shayna John',
                company: 'Careative inc',
                avatar: 'assets/images/review_02.png'
            }
        },
        {
            rating: 5,
            title: 'App working smoothly fast',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Shayna John',
                company: 'Creative inc',
                avatar: 'assets/images/review_01.png'
            }
        },
        {
            rating: 5,
            title: 'UI and UX is excellence !',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Jolly Shie',
                company: 'CEO - abc agency',
                avatar: 'assets/images/review_02.png'
            }
        },
        {
            rating: 5,
            title: 'Service support on time.',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Steve Joe',
                company: 'Vybo Creative',
                avatar: 'assets/images/review_01.png'
            }
        },
        {
            rating: 5,
            title: 'UI and UX is excellence !',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Jolly Shie',
                company: 'CEO - abc agency',
                avatar: 'assets/images/review_02.png'
            }
        },
        {
            rating: 5,
            title: 'Service support on time.',
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting ndustry lorem Ipsum has been the standard dummy text here.',
            reviewer: {
                name: 'Steve Joe',
                company: 'Vybo Creative',
                avatar: 'assets/images/review_01.png'
            }
        }
    ]

    return (
        <section className="row_am review_list_section">
            <div className="container">
                <div className="row">
                    {reviews.map((review, index) => (
                        <div className="col-lg-4 col-md-6" key={index}>
                            <div className="review_box">
                                <div className="rating">
                                    <ul>
                                        {[...Array(review.rating)].map((_, index) => (
                                            <li key={index}><span><i className="icofont-star"></i></span></li>
                                        ))}
                                    </ul>
                                </div>
                                <h3>{review.title}</h3>
                                <p>{review.content}</p>
                                <div className="reviewer">
                                    <div className="avtar">
                                        <img src={review.reviewer.avatar} alt="image" />
                                    </div>
                                    <div className="text">
                                        <h3>{review.reviewer.name}</h3>
                                        <span>{review.reviewer.company}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default First