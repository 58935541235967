import React from 'react'
import liveScreen from '../../../assets/images/LiveMarriageScreen.png';
import img1 from '../../../assets/images/secure_data.png'
import img2 from '../../../assets/images/functional.png'
import img3 from '../../../assets/images/live-chat.png'
import img4 from '../../../assets/images/support.png'
import Scureimg from "../../../assets/images/secure.png"
import Abtimg from "../../../assets/images/abt_functional.png"
import Communication from "../../../assets/images/communication.png"
import Supportimg from "../../../assets/images/abt_support.png"
import Frameimg from "../../../assets/images/features_frame.png"

const Main = ({ video }) => {
  return (
    <>
      {video ?
        <section className="row_am features_section video-features" id="features">
          <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
              <h2><span>Features</span> that make our app stand out!</h2>
              <p>Discover the future of matchmaking with live video calls, secure data, and advanced filters to help you find your perfect rishta or soulmate.</p>
            </div>
            <div className="feature_detail">
              <div className="left_data feature_box">
                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                  <div className="icon">
                    <img src={Scureimg} alt="Secure data" />
                  </div>
                  <div className="text">
                    <h4>Secure & Private</h4>
                    <p>All your data is safely stored with Amazon Web Services, and we offer secure logins through Apple and Facebook.</p>
                  </div>
                </div>
                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                  <div className="icon">
                    <img src={Abtimg} alt="Advanced Filters" />
                  </div>
                  <div className="text">
                    <h4>Advanced Filters</h4>
                    <p>Our powerful filter system allows you to find the perfect match based on your preferences, from age to location and more.</p>
                  </div>
                </div>
              </div>
              <div className="right_data feature_box">
                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                  <div className="icon">
                    <img src={Communication} alt="Live Matching" />
                  </div>
                  <div className="text">
                    <h4>Live Matching</h4>
                    <p>Connect face-to-face with potential rishtas through 10-minute video calls to instantly see if you’re a match.</p>
                  </div>
                </div>
                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                  <div className="icon">
                    <img src={Supportimg} alt="24/7 Support" />
                  </div>
                  <div className="text">
                    <h4>24/7 Support</h4>
                    <p>We're always here to help. Our support team is available around the clock to assist you with any questions.</p>
                  </div>
                </div>
              </div>
              <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img src={Frameimg} alt="Live video call interface" />
              </div>
            </div>
          </div>
        </section>
        :
        <section className="row_am features_section" id="features">
          <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
              <h2><span>Features</span> that make our app stand out!</h2>
              <p>Discover the future of matchmaking with live video calls, secure data, and advanced filters to help you find your perfect rishta or soulmate.</p>
            </div>
            <div className="feature_detail">
              <div className="left_data feature_box">
                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                  <div className="icon">
                    <img src={img1} alt="Secure data" />
                  </div>
                  <div className="text">
                    <h4>Secure & Private</h4>
                    <p>All your data is safely stored with Amazon Web Services, and we offer secure logins through Apple and Facebook.</p>
                  </div>
                </div>
                <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                  <div className="icon">
                    <img src={img2} alt="Advanced Filters" />
                  </div>
                  <div className="text">
                    <h4>Advanced Filters</h4>
                    <p>Our powerful filter system allows you to find the perfect match based on your preferences, from age to location and more.</p>
                  </div>
                </div>
              </div>
              <div className="right_data feature_box">
                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                  <div className="icon">
                    <img src={img3} alt="Live Matching" />
                  </div>
                  <div className="text">
                    <h4>Live Matching</h4>
                    <p>Connect face-to-face with potential rishtas through 10-minute video calls to instantly see if you’re a match.</p>
                  </div>
                </div>
                <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                  <div className="icon">
                    <img src={img4} alt="24/7 Support" />
                  </div>
                  <div className="text">
                    <h4>24/7 Support</h4>
                    <p>We're always here to help. Our support team is available around the clock to assist you with any questions.</p>
                  </div>
                </div>
              </div>
              <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img src={liveScreen} alt="Live video call interface" />
              </div>
            </div>
          </div>
        </section>}
    </>
  )
}

export default Main
