import React from 'react';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png";  // Default background image
import Review from './CookiePolicyText';  // Assuming the cookie policy content is in this component

const Main = () => {

    return (
        <>
            {/* Always display the breadcrumb with the default image */}
            <Bredcrumb no={1} title="Cookie Policy" tag="Cookie Policy" bgimg={BGImg} />

            {/* Cookie Policy content */}
            <Review />
        </>
    );
}

export default Main;
