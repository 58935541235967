import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        {
            imgSrc: "/assets/images/islamic_marriage_ceremony_nikah.png",
            time: "October 22nd, 2024",
            title: "Islamic Marriage Ceremony Nikah",
            content: "An in-depth look into the Islamic marriage ceremony, Nikah, its meaning, and rituals...",
            link: "/islamic-marriage-ceremony-nikah"
        },
        {
            imgSrc: "/assets/images/what_is_nikkah.png",
            time: "October 23rd, 2024",
            title: "What is Nikkah?",
            content: "Understanding the significance and process of Nikkah in Islam...",
            link: "/what-is-nikkah"
        },
        {
            imgSrc: "/assets/images/importance_of_nikah_sunni_islam.png",
            time: "October 24th, 2024",
            title: "Importance of Nikah in Sunni Islam",
            content: "Explore why Nikah holds a special place in Sunni Islam...",
            link: "/importance-of-nikah-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/significance_of_mahr_sunni_nikah.png",
            time: "October 25th, 2024",
            title: "Significance of Mahr in Sunni Nikah",
            content: "Learn about the essential role of Mahr in Sunni marriage...",
            link: "/significance-of-mahr-in-sunni-nikah"
        },
        {
            imgSrc: "/assets/images/sunni_muslim_interfaith_marriage.png",
            time: "October 26th, 2024",
            title: "Sunni Muslim Interfaith Marriage",
            content: "Explore the complexities and perspectives on interfaith marriage...",
            link: "/sunni-muslim-interfaith-marriage"
        },
        {
            imgSrc: "/assets/images/can_sunni_muslims_marry_people_of_other_faiths.png",
            time: "October 27th, 2024",
            title: "Can Sunni Muslims Marry People of Other Faiths?",
            content: "Understand the rules and guidance on interfaith marriage in Sunni Islam...",
            link: "/can-sunni-muslims-marry-people-of-other-faiths"
        },
        {
            imgSrc: "/assets/images/wali_guardian_role_in_sunni_marriage.png",
            time: "October 28th, 2024",
            title: "Wali Guardian Role in Sunni Marriage",
            content: "Learn about the role of a Wali (guardian) in a Sunni marriage...",
            link: "/wali-guardian-role-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/marriage_proposal_sunni_culture.png",
            time: "October 29th, 2024",
            title: "Marriage Proposal in Sunni Culture",
            content: "Explore how marriage proposals are conducted in Sunni culture...",
            link: "/marriage-proposal-in-sunni-culture"
        },
        {
            imgSrc: "/assets/images/nikah_walima_difference.png",
            time: "October 30th, 2024",
            title: "Nikah and Walima Difference",
            content: "Understanding the differences between Nikah and Walima in Islam...",
            link: "/nikah-walima-difference"
        },
        {
            imgSrc: "/assets/images/how_marriage_ceremony_conducted_sunni.png",
            time: "October 31st, 2024",
            title: "How Marriage Ceremony is Conducted in Sunni Islam",
            content: "Detailed guide on how a marriage ceremony is held in Sunni Islam...",
            link: "/how-marriage-ceremony-is-conducted-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/witnesses_in_sunni_nikah.png",
            time: "November 1st, 2024",
            title: "Witnesses in Sunni Nikah",
            content: "Learn about the importance of witnesses in a Sunni marriage...",
            link: "/witnesses-in-sunni-nikah"
        },
        {
            imgSrc: "/assets/images/can_sunni_muslims_have_civil_marriage.png",
            time: "November 2nd, 2024",
            title: "Can Sunni Muslims Have Civil Marriage?",
            content: "Exploring the permissibility and process of civil marriage in Sunni Islam...",
            link: "/can-sunni-muslims-have-civil-marriage"
        },
        {
            imgSrc: "/assets/images/how_should_mahr_be_set_in_sunni_marriage.png",
            time: "November 3rd, 2024",
            title: "How Should Mahr be Set in Sunni Marriage?",
            content: "Guidelines on setting Mahr for a Sunni marriage...",
            link: "/how-mahr-should-be-set-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/conditions_for_valid_nikah.png",
            time: "November 4th, 2024",
            title: "Conditions for a Valid Nikah",
            content: "Discover the necessary conditions for a Nikah to be valid in Sunni Islam...",
            link: "/conditions-for-valid-nikah"
        },
        {
            imgSrc: "/assets/images/is_forced_marriage_allowed_sunni_islam.png",
            time: "November 5th, 2024",
            title: "Is Forced Marriage Allowed in Sunni Islam?",
            content: "Understand Sunni Islam's stance on forced marriages...",
            link: "/is-forced-marriage-allowed-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni_islam_divorce_process.png",
            time: "November 6th, 2024",
            title: "Sunni Islam Divorce Process",
            content: "An overview of the divorce process in Sunni Islam...",
            link: "/sunni-islam-divorce-process"
        },
        {
            imgSrc: "/assets/images/sunni_woman_proposal_marriage.png",
            time: "November 7th, 2024",
            title: "Sunni Woman Proposing Marriage",
            content: "Learn how marriage proposals work when initiated by a Sunni woman...",
            link: "/sunni-woman-proposing-marriage"
        },
        {
            imgSrc: "/assets/images/types_of_mahr_in_sunni_islam.png",
            time: "November 8th, 2024",
            title: "Types of Mahr in Sunni Islam",
            content: "Different types of Mahr and their significance in Sunni marriages...",
            link: "/types-of-mahr-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/cousin_marriage_sunni_islam.png",
            time: "November 9th, 2024",
            title: "Cousin Marriage in Sunni Islam",
            content: "Understand the Islamic perspective on marrying cousins...",
            link: "/cousin-marriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/how_couples_interact_before_marriage.png",
            time: "November 10th, 2024",
            title: "How Couples Interact Before Marriage",
            content: "Guidelines for pre-marital interactions in Sunni Islam...",
            link: "/how-couples-interact-before-marriage"
        },
        {
            imgSrc: "/assets/images/role_of_istikhara_in_sunni_marriage.png",
            time: "November 11th, 2024",
            title: "Role of Istikhara in Sunni Marriage",
            content: "The importance of Istikhara before marriage in Sunni culture...",
            link: "/role-of-istikhara-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/can_sunni_muslims_marry_shia_muslims.png",
            time: "November 12th, 2024",
            title: "Can Sunni Muslims Marry Shia Muslims?",
            content: "Exploring inter-sect marriage between Sunnis and Shias...",
            link: "/can-sunni-muslims-marry-shia-muslims"
        },
        {
            imgSrc: "/assets/images/premarital_relationships_in_sunni_islam.png",
            time: "November 13th, 2024",
            title: "Premarital Relationships in Sunni Islam",
            content: "Understanding Sunni Islam's teachings on premarital relationships...",
            link: "/premarital-relationships-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/role_of_family_in_sunni_marriage.png",
            time: "November 14th, 2024",
            title: "Role of Family in Sunni Marriage",
            content: "The role and responsibilities of families in Sunni marriage...",
            link: "/role-of-family-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/how_sunni_islam_views_polygamy.png",
            time: "November 15th, 2024",
            title: "How Sunni Islam Views Polygamy",
            content: "An overview of polygamy within the context of Sunni teachings...",
            link: "/how-sunni-islam-views-polygamy"
        },
        {
            imgSrc: "/assets/images/sunni_marriage_ceremony.png",
            time: "November 16th, 2024",
            title: "Sunni Marriage Ceremony",
            content: "Learn about the traditional elements of a Sunni marriage ceremony...",
            link: "/sunni-marriage-ceremony"
        },
        {
            imgSrc: "/assets/images/witnesses_in_sunni_nikah.png",
            time: "November 17th, 2024",
            title: "Witnesses in Sunni Nikah",
            content: "The significance of witnesses in the marriage ceremony...",
            link: "/witnesses-in-sunni-nikah"
        },
        {
            imgSrc: "/assets/images/can_sunni_muslims_have_civil_marriage.png",
            time: "November 18th, 2024",
            title: "Can Sunni Muslims Have Civil Marriage?",
            content: "Understanding the civil marriage process for Sunni Muslims...",
            link: "/can-sunni-muslims-have-civil-marriage"
        },
        {
            imgSrc: "/assets/images/how_mahr_is_set_in_sunni_marriage.png",
            time: "November 19th, 2024",
            title: "How Mahr is Set in Sunni Marriage",
            content: "Learn the guidelines on determining Mahr in Sunni marriages...",
            link: "/how-mahr-is-set-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/valid_sunni_nikah_conditions.png",
            time: "November 20th, 2024",
            title: "Valid Sunni Nikah Conditions",
            content: "Key requirements for a valid Nikah according to Sunni teachings...",
            link: "/valid-sunni-nikah-conditions"
        },
        {
            imgSrc: "/assets/images/is_forced_marriage_allowed_in_sunni_islam.png",
            time: "November 21st, 2024",
            title: "Is Forced Marriage Allowed in Sunni Islam?",
            content: "Exploring the views on forced marriages in Sunni culture...",
            link: "/is-forced-marriage-allowed-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/how_sunni_islam_addresses_divorce.png",
            time: "November 22nd, 2024",
            title: "How Sunni Islam Addresses Divorce",
            content: "A look into the divorce laws and practices in Sunni Islam...",
            link: "/how-sunni-islam-addresses-divorce"
        },
        {
            imgSrc: "/assets/images/sunni_woman_propose_marriage.png",
            time: "November 23rd, 2024",
            title: "Sunni Woman Proposing Marriage",
            content: "Learn about the role of women proposing marriage in Sunni culture...",
            link: "/sunni-woman-proposing-marriage"
        },
        {
            imgSrc: "/assets/images/types_of_mahr_sunni_islam.png",
            time: "November 24th, 2024",
            title: "Types of Mahr in Sunni Islam",
            content: "Various forms of Mahr and their roles in Sunni marriages...",
            link: "/types-of-mahr-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/cousin_marriage_in_sunni_islam.png",
            time: "November 25th, 2024",
            title: "Cousin Marriage in Sunni Islam",
            content: "The tradition and acceptance of cousin marriage in Islam...",
            link: "/cousin-marriage-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/how_should_couple_interact_before_marriage.png",
            time: "November 26th, 2024",
            title: "How Should Couples Interact Before Marriage?",
            content: "Guidance on interactions between couples in Sunni Islam...",
            link: "/how-should-couples-interact-before-marriage"
        },
        {
            imgSrc: "/assets/images/sunni_woman_wali_marriage.png",
            time: "November 27th, 2024",
            title: "Sunni Woman and Wali in Marriage",
            content: "Roles and responsibilities of the Wali in Sunni marriages...",
            link: "/sunni-woman-and-wali-in-marriage"
        },
        {
            imgSrc: "/assets/images/rights_of_wife_in_sunni_islam.png",
            time: "November 28th, 2024",
            title: "Rights of Wife in Sunni Islam",
            content: "Learn about the rights accorded to a wife in Sunni marriage...",
            link: "/rights-of-wife-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/rights_of_husband_in_sunni_islam.png",
            time: "November 29th, 2024",
            title: "Rights of Husband in Sunni Islam",
            content: "Understand the husband's rights and responsibilities in marriage...",
            link: "/rights-of-husband-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni_marriage_contract.png",
            time: "November 30th, 2024",
            title: "Sunni Marriage Contract",
            content: "The importance and elements of a marriage contract in Sunni Islam...",
            link: "/sunni-marriage-contract"
        },
        {
            imgSrc: "/assets/images/sunni_marriage_contract_conditions.png",
            time: "December 1st, 2024",
            title: "Sunni Marriage Contract Conditions",
            content: "Essential conditions and stipulations in Sunni marriage contracts...",
            link: "/sunni-marriage-contract-conditions"
        },
        {
            imgSrc: "/assets/images/divorce_in_sunni_islam.png",
            time: "December 2nd, 2024",
            title: "Divorce in Sunni Islam",
            content: "A guide to divorce proceedings within Sunni teachings...",
            link: "/divorce-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/what_is_iddah_sunni_islam.png",
            time: "December 3rd, 2024",
            title: "What is Iddah in Sunni Islam?",
            content: "Learn about the waiting period of Iddah in Sunni Islam...",
            link: "/what-is-iddah-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/marriage_annulment_sunni_islam.png",
            time: "December 4th, 2024",
            title: "Marriage Annulment in Sunni Islam",
            content: "Understand when and how a marriage can be annulled...",
            link: "/marriage-annulment-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/sunni_view_on_marriage_with_non_muslim.png",
            time: "December 5th, 2024",
            title: "Sunni View on Marriage with Non-Muslims",
            content: "Explore the rulings around marrying a non-Muslim partner...",
            link: "/sunni-view-on-marriage-with-non-muslims"
        },
        {
            imgSrc: "/assets/images/duties_of_husband_in_sunni_islam.png",
            time: "December 6th, 2024",
            title: "Duties of Husband in Sunni Islam",
            content: "Responsibilities of a husband as per Sunni teachings...",
            link: "/duties-of-husband-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/rights_of_wife_in_sunni_islam.png",
            time: "December 7th, 2024",
            title: "Rights of Wife in Sunni Islam",
            content: "Discover the rights provided to wives under Sunni law...",
            link: "/rights-of-wife-in-sunni-islam"
        },
        {
            imgSrc: "/assets/images/how_sunni_islam_addresses_domestic_responsibilities.png",
            time: "December 8th, 2024",
            title: "How Sunni Islam Addresses Domestic Responsibilities",
            content: "An overview of domestic roles within a Sunni marriage...",
            link: "/how-sunni-islam-addresses-domestic-responsibilities"
        },
        {
            imgSrc: "/assets/images/wali_role_in_sunni_marriage.png",
            time: "December 9th, 2024",
            title: "Wali Role in Sunni Marriage",
            content: "Importance and role of Wali in a Sunni marriage...",
            link: "/wali-role-in-sunni-marriage"
        },
        {
            imgSrc: "/assets/images/walima_in_sunni_islam.png",
            time: "December 10th, 2024",
            title: "Walima in Sunni Islam",
            content: "Learn about the celebration of Walima after Nikah...",
            link: "/walima-in-sunni-islam"
        }
    ];
    

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                            <img src={story.imgSrc} alt={story.title}   style={{ width: "410px", height: "290px" }} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                        <ul>
                            <li><Link to="../blog3" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                            <li><Link to="../blog">1</Link></li>
                            <li><Link to="../blog2">2</Link></li>
                            <li><Link to="../blog3">3</Link></li>
                            <li><Link to="../blog4" className="active">4</Link></li>
                            <li><Link to="../blog5">5</Link></li>
                            <li><Link to="../blog6">6</Link></li>
                            <li><Link to="../blog5" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                            {/* <li><Link to="#" className="next">Next <i className="icofont-arrow-right"></i></Link></li> */}
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllBlog;
