import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from '../component/Navbar/Main';
import Footer from '../component/Footer/Main';
import Home from '../component/Home/Main';
import Review from '../component/Pages/Review/Main';
import Contact from '../component/Contact/Main';
import Faq from '../component/Pages/Faq/Main';
import SignIn from '../component/Pages/SignIn/Main';
import SignUp from '../component/Pages/SignUp/Main';
import Blog from '../component/Blog/AllBlog/Main';
import Blog2 from '../component/Blog/AllBlog2/Main';
import Blog3 from '../component/Blog/AllBlog3/Main';
import Blog4 from '../component/Blog/AllBlog4/Main';
import Blog5 from '../component/Blog/AllBlog5/Main';
import Blog6 from '../component/Blog/AllBlog6/Main';
import FeaturedBlogPost from '../component/Blog/FeaturedBlogPost/Main';
import Pricing from '../component/Pricing/Main';
import PrivacyPolicy from '../component/Legal/PrivacyPolicy/Main';
import TermsOfService from '../component/Legal/TermsOfService/Main';
import CookiePolicy from '../component/Legal/CookiePolicy/Main';

import IndividualBlogPost from '../component/Blog/IndividualBlogPost/IndividualBlogPost';  // Why Marriage is Good in Islam
import IndividualBlogPost1 from '../component/Blog/IndividualBlogPost/IndividualBlogPost1';  // Top 5 Reasons Muslima Women Choose Halal Dating Apps
import IndividualBlogPost2 from '../component/Blog/IndividualBlogPost/IndividualBlogPost2';  // Empowering Muslim Women: How Muslima are Redefining Marriage
import IndividualBlogPost3 from '../component/Blog/IndividualBlogPost/IndividualBlogPost3';  // How Muslimas Can Find Compatible Matches with Live Marriage Features
import IndividualBlogPost4 from '../component/Blog/IndividualBlogPost/IndividualBlogPost4';  // 10 Essential Tips for Muslimas Looking for Marriage Online
import IndividualBlogPost5 from '../component/Blog/IndividualBlogPost/IndividualBlogPost5';  // What Is a Nikkah? Understanding the Islamic Marriage Contract
import IndividualBlogPost6 from '../component/Blog/IndividualBlogPost/IndividualBlogPost6';  // How to Find Your Perfect Nikkah Match Online
import IndividualBlogPost7 from '../component/Blog/IndividualBlogPost/IndividualBlogPost7';  // Nikkah in Modern Times
import IndividualBlogPost8 from '../component/Blog/IndividualBlogPost/IndividualBlogPost8';  // Preparing for Nikkah
import IndividualBlogPost9 from '../component/Blog/IndividualBlogPost/IndividualBlogPost9';  // Nikkah Tips for Busy Professionals
import IndividualBlogPost10 from '../component/Blog/IndividualBlogPost/IndividualBlogPost10'; // Muslim Marriage Made Easy
import IndividualBlogPost11 from '../component/Blog/IndividualBlogPost/IndividualBlogPost11'; // Why Muslim Marriage Apps Are the Future of Finding Love
import IndividualBlogPost12 from '../component/Blog/IndividualBlogPost/IndividualBlogPost12'; // Muslim Marriage Expectations
import IndividualBlogPost13 from '../component/Blog/IndividualBlogPost/IndividualBlogPost13'; // Top 10 Qualities to Look for in a Muslim Marriage Partner
import IndividualBlogPost14 from '../component/Blog/IndividualBlogPost/IndividualBlogPost14'; // What Is Halal Dating? A Complete Guide for Muslims
import IndividualBlogPost15 from '../component/Blog/IndividualBlogPost/IndividualBlogPost15'; // How Halal Dating Apps Help You Stay True to Islamic Values
import IndividualBlogPost16 from '../component/Blog/IndividualBlogPost/IndividualBlogPost16'; // How to Keep Your Relationship Halal While Dating Online
import IndividualBlogPost17 from '../component/Blog/IndividualBlogPost/IndividualBlogPost17'; // Halal Dating vs. Traditional Dating
import IndividualBlogPost18 from '../component/Blog/IndividualBlogPost/IndividualBlogPost18'; // How Muslim Dating Apps Are Changing the Way Muslims Find Love
import IndividualBlogPost19 from '../component/Blog/IndividualBlogPost/IndividualBlogPost19'; // Live Marriage: The Future of Muslim Dating Apps

// New Blog Post Imports
import IndividualBlogPost20 from '../component/Blog/IndividualBlogPost/IndividualBlogPost20'; // Nikah Guide for Muslims
import IndividualBlogPost21 from '../component/Blog/IndividualBlogPost/IndividualBlogPost21'; // Muslim Marriage Reddit Guide
import IndividualBlogPost22 from '../component/Blog/IndividualBlogPost/IndividualBlogPost22'; // Islamic Marriage Rules Guide
import IndividualBlogPost23 from '../component/Blog/IndividualBlogPost/IndividualBlogPost23'; // Muslim Universal Marriage Bureau
import IndividualBlogPost24 from '../component/Blog/IndividualBlogPost/IndividualBlogPost24'; // Muslim Wedding Contract
import IndividualBlogPost25 from '../component/Blog/IndividualBlogPost/IndividualBlogPost25'; // Muslim Wedding Rules
import IndividualBlogPost26 from '../component/Blog/IndividualBlogPost/IndividualBlogPost26'; // Islam and Marriage
import IndividualBlogPost27 from '../component/Blog/IndividualBlogPost/IndividualBlogPost27'; // Temporary Marriage in Islam
import IndividualBlogPost28 from '../component/Blog/IndividualBlogPost/IndividualBlogPost28'; // Christian and Muslim Marriage
import IndividualBlogPost29 from '../component/Blog/IndividualBlogPost/IndividualBlogPost29'; // Islamic Nikah
import IndividualBlogPost30 from '../component/Blog/IndividualBlogPost/IndividualBlogPost30'; // Nikah Marriage
import IndividualBlogPost31 from '../component/Blog/IndividualBlogPost/IndividualBlogPost31'; // Muslim Temporary Marriage
import IndividualBlogPost32 from '../component/Blog/IndividualBlogPost/IndividualBlogPost32'; // Muslim Marriage Contract
import IndividualBlogPost33 from '../component/Blog/IndividualBlogPost/IndividualBlogPost33'; // Islamic Matrimonial Site
import IndividualBlogPost34 from '../component/Blog/IndividualBlogPost/IndividualBlogPost34'; // Reddit Islam Marriage
import IndividualBlogPost35 from '../component/Blog/IndividualBlogPost/IndividualBlogPost35'; // Islamic Wedding
import IndividualBlogPost36 from '../component/Blog/IndividualBlogPost/IndividualBlogPost36'; // Nikah Ceremony
import IndividualBlogPost37 from '../component/Blog/IndividualBlogPost/IndividualBlogPost37'; // Half Our Deen
import IndividualBlogPost38 from '../component/Blog/IndividualBlogPost/IndividualBlogPost38'; // Mutah Marriage
import IndividualBlogPost39 from '../component/Blog/IndividualBlogPost/IndividualBlogPost39'; // Muslim Wedding
import IndividualBlogPost40 from '../component/Blog/IndividualBlogPost/IndividualBlogPost40'; // Reddit Muslim Marriage
import IndividualBlogPost41 from '../component/Blog/IndividualBlogPost/IndividualBlogPost41'; // Simple Nikah
import IndividualBlogPost42 from '../component/Blog/IndividualBlogPost/IndividualBlogPost42'; // Muslim Traditional Wedding
import IndividualBlogPost43 from '../component/Blog/IndividualBlogPost/IndividualBlogPost43';  // Muslim Marriage Sites


import IndividualBlogPost44 from '../component/Blog/IndividualBlogPost/IndividualBlogPost44';  // Islamic Marriage Contract
import IndividualBlogPost45 from '../component/Blog/IndividualBlogPost/IndividualBlogPost45';  // Arabic Marriage
import IndividualBlogPost46 from '../component/Blog/IndividualBlogPost/IndividualBlogPost46';  // Understanding Islamic Wedding Contract
import IndividualBlogPost47 from '../component/Blog/IndividualBlogPost/IndividualBlogPost47';  // Islamic Nikkah
import IndividualBlogPost48 from '../component/Blog/IndividualBlogPost/IndividualBlogPost48';  // Islamic Wedding Rules
import IndividualBlogPost49 from '../component/Blog/IndividualBlogPost/IndividualBlogPost49';  // Islamic Marriage Ceremony Nikah
import IndividualBlogPost50 from '../component/Blog/IndividualBlogPost/IndividualBlogPost50';  // Islam Wedding Ceremony
import IndividualBlogPost51 from '../component/Blog/IndividualBlogPost/IndividualBlogPost51';  // Understanding Marriage in Islam
import IndividualBlogPost52 from '../component/Blog/IndividualBlogPost/IndividualBlogPost52';  // Islam Marriage Ceremony
import IndividualBlogPost53 from '../component/Blog/IndividualBlogPost/IndividualBlogPost53';  // Islam and Cousin Marriage
import IndividualBlogPost54 from '../component/Blog/IndividualBlogPost/IndividualBlogPost54';  // Marriage According to Islam
import IndividualBlogPost55 from '../component/Blog/IndividualBlogPost/IndividualBlogPost55';  // Marrying a Christian in Islam
import IndividualBlogPost56 from '../component/Blog/IndividualBlogPost/IndividualBlogPost56';  // Multiple Marriage in Islam
import IndividualBlogPost57 from '../component/Blog/IndividualBlogPost/IndividualBlogPost57';  // Multiple Wives in Islam
import IndividualBlogPost58 from '../component/Blog/IndividualBlogPost/IndividualBlogPost58';  // Muslim and Marriage
import IndividualBlogPost59 from '../component/Blog/IndividualBlogPost/IndividualBlogPost59';  // Cousin Marriage in Islam
import IndividualBlogPost60 from '../component/Blog/IndividualBlogPost/IndividualBlogPost60';  // Muslims Can Marry Christians
import IndividualBlogPost61 from '../component/Blog/IndividualBlogPost/IndividualBlogPost61';  // Muslim Cousin Marriage
import IndividualBlogPost62 from '../component/Blog/IndividualBlogPost/IndividualBlogPost62';  // Muslim Marriage Ceremony
import IndividualBlogPost63 from '../component/Blog/IndividualBlogPost/IndividualBlogPost63';  // Muslim Marriage is a Contract
import IndividualBlogPost64 from '../component/Blog/IndividualBlogPost/IndividualBlogPost64';  // Muslim Religion Marriage
import IndividualBlogPost65 from '../component/Blog/IndividualBlogPost/IndividualBlogPost65';  // Muslim Religion Marriage Rules
import IndividualBlogPost66 from '../component/Blog/IndividualBlogPost/IndividualBlogPost66';  // Muslim Marriage Rules
import IndividualBlogPost67 from '../component/Blog/IndividualBlogPost/IndividualBlogPost67';  // Muslim Wedding Ceremony
import IndividualBlogPost68 from '../component/Blog/IndividualBlogPost/IndividualBlogPost68';  // Traditional Islamic Wedding
import IndividualBlogPost69 from '../component/Blog/IndividualBlogPost/IndividualBlogPost69';  // Muslim Matrimony
import IndividualBlogPost70 from '../component/Blog/IndividualBlogPost/IndividualBlogPost70';  // Temporary Nikah in Islam
import IndividualBlogPost71 from '../component/Blog/IndividualBlogPost/IndividualBlogPost71';  // Islamic Dating
import IndividualBlogPost72 from '../component/Blog/IndividualBlogPost/IndividualBlogPost72';  // Muslim Matrimonial Websites
import IndividualBlogPost73 from '../component/Blog/IndividualBlogPost/IndividualBlogPost73';  // Muslim 4 Marriage
import IndividualBlogPost74 from '../component/Blog/IndividualBlogPost/IndividualBlogPost74';  // Muslima Dating
import IndividualBlogPost75 from '../component/Blog/IndividualBlogPost/IndividualBlogPost75';  // Islamic Marriage Sites
import IndividualBlogPost76 from '../component/Blog/IndividualBlogPost/IndividualBlogPost76';  // Islamic Marriage Website
import IndividualBlogPost77 from '../component/Blog/IndividualBlogPost/IndividualBlogPost77';  // Exploring Muslim Marriage Sites
import IndividualBlogPost78 from '../component/Blog/IndividualBlogPost/IndividualBlogPost78';  // Muslim Dating
import IndividualBlogPost79 from '../component/Blog/IndividualBlogPost/IndividualBlogPost79';  // The Ultimate Guide to Muslim Marriage Websites
import IndividualBlogPost80 from '../component/Blog/IndividualBlogPost/IndividualBlogPost80';  // Best Muslim Dating Apps
import IndividualBlogPost81 from '../component/Blog/IndividualBlogPost/IndividualBlogPost81';  // Marriage in Islam
import IndividualBlogPost82 from '../component/Blog/IndividualBlogPost/IndividualBlogPost82';  // Islamic Matrimonial Website
import IndividualBlogPost83 from '../component/Blog/IndividualBlogPost/IndividualBlogPost83';  // Unhappy Marriage in Islam
import IndividualBlogPost84 from '../component/Blog/IndividualBlogPost/IndividualBlogPost84';  // Muslim Couple Wedding

import IndividualBlogPost85 from '../component/Blog/IndividualBlogPost/IndividualBlogPost85';   // Islamic Marriage Ceremony Nikah
import IndividualBlogPost86 from '../component/Blog/IndividualBlogPost/IndividualBlogPost86';   // What Is Nikkah?
import IndividualBlogPost87 from '../component/Blog/IndividualBlogPost/IndividualBlogPost87';   // Importance of Nikah in Sunni Islam
import IndividualBlogPost88 from '../component/Blog/IndividualBlogPost/IndividualBlogPost88';   // Significance of Mahr in Sunni Nikah
import IndividualBlogPost89 from '../component/Blog/IndividualBlogPost/IndividualBlogPost89';   // Sunni Muslim Interfaith Marriage
import IndividualBlogPost90 from '../component/Blog/IndividualBlogPost/IndividualBlogPost90';   // Can Sunni Muslims Marry People of Other Faiths?
import IndividualBlogPost91 from '../component/Blog/IndividualBlogPost/IndividualBlogPost91';   // Wali Guardian Role in Sunni Marriage
import IndividualBlogPost92 from '../component/Blog/IndividualBlogPost/IndividualBlogPost92';   // Marriage Proposal in Sunni Culture
import IndividualBlogPost93 from '../component/Blog/IndividualBlogPost/IndividualBlogPost93';   // Nikah and Walima Difference
import IndividualBlogPost94 from '../component/Blog/IndividualBlogPost/IndividualBlogPost94';   // How Marriage Ceremony is Conducted in Sunni Islam
import IndividualBlogPost95 from '../component/Blog/IndividualBlogPost/IndividualBlogPost95';   // Witnesses in Sunni Nikah
import IndividualBlogPost96 from '../component/Blog/IndividualBlogPost/IndividualBlogPost96';   // Can Sunni Muslims Have Civil Marriage?
import IndividualBlogPost97 from '../component/Blog/IndividualBlogPost/IndividualBlogPost97';   // How Mahr Should Be Set in Sunni Marriage
import IndividualBlogPost98 from '../component/Blog/IndividualBlogPost/IndividualBlogPost98';   // Conditions for Valid Nikah
import IndividualBlogPost99 from '../component/Blog/IndividualBlogPost/IndividualBlogPost99';   // Is Forced Marriage Allowed in Sunni Islam?
import IndividualBlogPost100 from '../component/Blog/IndividualBlogPost/IndividualBlogPost100'; // Sunni Islam Divorce Process
import IndividualBlogPost101 from '../component/Blog/IndividualBlogPost/IndividualBlogPost101'; // Sunni Woman Proposing Marriage
import IndividualBlogPost102 from '../component/Blog/IndividualBlogPost/IndividualBlogPost102'; // Types of Mahr in Sunni Islam
import IndividualBlogPost103 from '../component/Blog/IndividualBlogPost/IndividualBlogPost103'; // Cousin Marriage in Sunni Islam
import IndividualBlogPost104 from '../component/Blog/IndividualBlogPost/IndividualBlogPost104'; // How Couples Interact Before Marriage
import IndividualBlogPost105 from '../component/Blog/IndividualBlogPost/IndividualBlogPost105'; // Role of Istikhara in Sunni Marriage
import IndividualBlogPost106 from '../component/Blog/IndividualBlogPost/IndividualBlogPost106'; // Can Sunni Muslims Marry Shia Muslims?
import IndividualBlogPost107 from '../component/Blog/IndividualBlogPost/IndividualBlogPost107'; // Premarital Relationships in Sunni Islam
import IndividualBlogPost108 from '../component/Blog/IndividualBlogPost/IndividualBlogPost108'; // Role of Family in Sunni Marriage
import IndividualBlogPost109 from '../component/Blog/IndividualBlogPost/IndividualBlogPost109'; // How Sunni Islam Views Polygamy
import IndividualBlogPost110 from '../component/Blog/IndividualBlogPost/IndividualBlogPost110'; // Sunni Marriage Ceremony
import IndividualBlogPost111 from '../component/Blog/IndividualBlogPost/IndividualBlogPost111'; // Witnesses in Sunni Nikah
import IndividualBlogPost112 from '../component/Blog/IndividualBlogPost/IndividualBlogPost112'; // Can Sunni Muslims Have Civil Marriage?
import IndividualBlogPost113 from '../component/Blog/IndividualBlogPost/IndividualBlogPost113'; // How Mahr Is Set in Sunni Marriage
import IndividualBlogPost114 from '../component/Blog/IndividualBlogPost/IndividualBlogPost114'; // Valid Sunni Nikah Conditions
import IndividualBlogPost115 from '../component/Blog/IndividualBlogPost/IndividualBlogPost115'; // Is Forced Marriage Allowed in Sunni Islam?
import IndividualBlogPost116 from '../component/Blog/IndividualBlogPost/IndividualBlogPost116'; // How Sunni Islam Addresses Divorce
import IndividualBlogPost117 from '../component/Blog/IndividualBlogPost/IndividualBlogPost117'; // Sunni Woman Proposing Marriage
import IndividualBlogPost118 from '../component/Blog/IndividualBlogPost/IndividualBlogPost118'; // Types of Mahr in Sunni Islam
import IndividualBlogPost119 from '../component/Blog/IndividualBlogPost/IndividualBlogPost119'; // Cousin Marriage in Sunni Islam
import IndividualBlogPost120 from '../component/Blog/IndividualBlogPost/IndividualBlogPost120'; // How Should Couples Interact Before Marriage?
import IndividualBlogPost121 from '../component/Blog/IndividualBlogPost/IndividualBlogPost121'; // Sunni Woman and Wali in Marriage
import IndividualBlogPost122 from '../component/Blog/IndividualBlogPost/IndividualBlogPost122'; // Rights of Wife in Sunni Islam
import IndividualBlogPost123 from '../component/Blog/IndividualBlogPost/IndividualBlogPost123'; // Rights of Husband in Sunni Islam
import IndividualBlogPost124 from '../component/Blog/IndividualBlogPost/IndividualBlogPost124'; // Sunni Marriage Contract
import IndividualBlogPost125 from '../component/Blog/IndividualBlogPost/IndividualBlogPost125'; // Sunni Marriage Contract Conditions
import IndividualBlogPost126 from '../component/Blog/IndividualBlogPost/IndividualBlogPost126'; // Divorce in Sunni Islam
import IndividualBlogPost127 from '../component/Blog/IndividualBlogPost/IndividualBlogPost127'; // What Is Iddah in Sunni Islam?
import IndividualBlogPost128 from '../component/Blog/IndividualBlogPost/IndividualBlogPost128'; // Divorce in Sunni Islam
import IndividualBlogPost129 from '../component/Blog/IndividualBlogPost/IndividualBlogPost129'; // Marriage Annulment in Sunni Islam
import IndividualBlogPost130 from '../component/Blog/IndividualBlogPost/IndividualBlogPost130'; // Sunni View on Marriage with Non-Muslim
import IndividualBlogPost131 from '../component/Blog/IndividualBlogPost/IndividualBlogPost131'; // Duties of Husband in Sunni Islam
import IndividualBlogPost132 from '../component/Blog/IndividualBlogPost/IndividualBlogPost132'; // Rights of Wife in Sunni Islam
import IndividualBlogPost133 from '../component/Blog/IndividualBlogPost/IndividualBlogPost133'; // How Sunni Islam Addresses Domestic Responsibilities
import IndividualBlogPost134 from '../component/Blog/IndividualBlogPost/IndividualBlogPost134'; // Wali Role in Sunni Marriage
import IndividualBlogPost135 from '../component/Blog/IndividualBlogPost/IndividualBlogPost135'; // Walima in Sunni Islam
import IndividualBlogPost136 from '../component/Blog/IndividualBlogPost/IndividualBlogPost136'; // Can Sunni Couple Get Engaged Without Family Approval?
import IndividualBlogPost138 from '../component/Blog/IndividualBlogPost/IndividualBlogPost138'; // Role of Consent in Sunni Marriage
import IndividualBlogPost139 from '../component/Blog/IndividualBlogPost/IndividualBlogPost139'; // Premarital Counseling in Sunni Islam
import IndividualBlogPost140 from '../component/Blog/IndividualBlogPost/IndividualBlogPost140'; // Iddah Observance Rules
import IndividualBlogPost141 from '../component/Blog/IndividualBlogPost/IndividualBlogPost141'; // Child Custody in Sunni Islam
import IndividualBlogPost142 from '../component/Blog/IndividualBlogPost/IndividualBlogPost142'; // Inheritance Rights in Marriage
import IndividualBlogPost143 from '../component/Blog/IndividualBlogPost/IndividualBlogPost143'; // Sunni Woman Remarrying
import IndividualBlogPost144 from '../component/Blog/IndividualBlogPost/IndividualBlogPost144'; // Sunni Islam Second Marriage
import IndividualBlogPost145 from '../component/Blog/IndividualBlogPost/IndividualBlogPost145'; // Proper Age for Marriage in Sunni Islam
import IndividualBlogPost146 from '../component/Blog/IndividualBlogPost/IndividualBlogPost146'; // Marital Discord Resolution in Sunni Islam
import IndividualBlogPost147 from '../component/Blog/IndividualBlogPost/IndividualBlogPost147'; // Obligations of Parents in Sunni Wedding
import IndividualBlogPost148 from '../component/Blog/IndividualBlogPost/IndividualBlogPost148'; // Family Planning in Sunni Islam
import IndividualBlogPost149 from '../component/Blog/IndividualBlogPost/IndividualBlogPost149'; // Can Sunni Husband Restrict Travel?
import IndividualBlogPost150 from '../component/Blog/IndividualBlogPost/IndividualBlogPost150'; // Prenuptial Agreements in Sunni Islam
import IndividualBlogPost151 from '../component/Blog/IndividualBlogPost/IndividualBlogPost151'; // Healthy Muslim Couple
import IndividualBlogPost152 from '../component/Blog/IndividualBlogPost/IndividualBlogPost152'; // Sunni Woman Working Outside Home
import IndividualBlogPost153 from '../component/Blog/IndividualBlogPost/IndividualBlogPost153'; // Inheritance Between Spouses in Sunni Islam
import IndividualBlogPost154 from '../component/Blog/IndividualBlogPost/IndividualBlogPost154'; // Sunni Wedding Ceremony Requirements
import IndividualBlogPost155 from '../component/Blog/IndividualBlogPost/IndividualBlogPost155'; // Sunni Marriage Preparation
import IndividualBlogPost156 from '../component/Blog/IndividualBlogPost/IndividualBlogPost156'; // Role of Wali in Sunni Nikah
import IndividualBlogPost157 from '../component/Blog/IndividualBlogPost/IndividualBlogPost157'; // Mahr in Sunni Marriage
import IndividualBlogPost158 from '../component/Blog/IndividualBlogPost/IndividualBlogPost158'; // How Nikah Should Be Registered in Sunni Islam
import IndividualBlogPost159 from '../component/Blog/IndividualBlogPost/IndividualBlogPost159'; // Sunni View on Romantic Love Before Marriage
import IndividualBlogPost160 from '../component/Blog/IndividualBlogPost/IndividualBlogPost160'; // Wedding Expenses in Sunni Culture
import IndividualBlogPost161 from '../component/Blog/IndividualBlogPost/IndividualBlogPost161'; // Purpose of Marriage in Sunni Islam
import IndividualBlogPost162 from '../component/Blog/IndividualBlogPost/IndividualBlogPost162'; // Sunni Nikah Witnesses
import IndividualBlogPost163 from '../component/Blog/IndividualBlogPost/IndividualBlogPost163'; // Marriage Strengthening Faith
import IndividualBlogPost164 from '../component/Blog/IndividualBlogPost/IndividualBlogPost164'; // Sunni Islam Dowry (Jahaz)
import IndividualBlogPost165 from '../component/Blog/IndividualBlogPost/IndividualBlogPost165'; // Halal Dating in Sunni Islam
import IndividualBlogPost166 from '../component/Blog/IndividualBlogPost/IndividualBlogPost166'; // Marriage Proposals in Sunni Culture
import IndividualBlogPost167 from '../component/Blog/IndividualBlogPost/IndividualBlogPost167'; // Sunni Couple Marriage Without Family Involvement
import IndividualBlogPost168 from '../component/Blog/IndividualBlogPost/IndividualBlogPost168'; // Marriage to a Revert in Sunni Islam
import IndividualBlogPost169 from '../component/Blog/IndividualBlogPost/IndividualBlogPost169'; // Sunni Couple Finances
import IndividualBlogPost170 from '../component/Blog/IndividualBlogPost/IndividualBlogPost170'; // Premarital Education in Sunni Islam
import IndividualBlogPost171 from '../component/Blog/IndividualBlogPost/IndividualBlogPost171'; // Divorce Finalization in Sunni Islam
import IndividualBlogPost172 from '../component/Blog/IndividualBlogPost/IndividualBlogPost172'; // Khula in Sunni Islam

import IndividualBlogPost174 from '../component/Blog/IndividualBlogPost/IndividualBlogPost174'; // Child Support in Sunni Divorce
import IndividualBlogPost175 from '../component/Blog/IndividualBlogPost/IndividualBlogPost175'; // Significance of Walima in Sunni Islam
import IndividualBlogPost176 from '../component/Blog/IndividualBlogPost/IndividualBlogPost176'; // Sunni Wedding Invitation Etiquette
import IndividualBlogPost177 from '../component/Blog/IndividualBlogPost/IndividualBlogPost177'; // Sunni Couple Family Boundaries
import IndividualBlogPost178 from '../component/Blog/IndividualBlogPost/IndividualBlogPost178'; // Honesty in Sunni Marriage
import IndividualBlogPost179 from '../component/Blog/IndividualBlogPost/IndividualBlogPost179'; // Marriage Disputes in Sunni Culture
import IndividualBlogPost180 from '../component/Blog/IndividualBlogPost/IndividualBlogPost180'; // Wali Responsibility in Sunni Islam

import IndividualBlogPost182 from '../component/Blog/IndividualBlogPost/IndividualBlogPost182'; // Marriage Gifts in Sunni Wedding
import IndividualBlogPost183 from '../component/Blog/IndividualBlogPost/IndividualBlogPost183'; // Financial Transparency in Marriage
import IndividualBlogPost184 from '../component/Blog/IndividualBlogPost/IndividualBlogPost184'; // Sunni Couple Living Separately
import IndividualBlogPost185 from '../component/Blog/IndividualBlogPost/IndividualBlogPost185'; // Modesty in Islam
import IndividualBlogPost186 from '../component/Blog/IndividualBlogPost/IndividualBlogPost186'; // Benefits of Marriage in Islam
import IndividualBlogPost187 from '../component/Blog/IndividualBlogPost/IndividualBlogPost187'; // Parental Involvement in Sunni Marriage
import IndividualBlogPost188 from '../component/Blog/IndividualBlogPost/IndividualBlogPost188'; // Role of Extended Family in Sunni Marriage
import IndividualBlogPost189 from '../component/Blog/IndividualBlogPost/IndividualBlogPost189'; // Widow Remarriage in Sunni Islam
import IndividualBlogPost190 from '../component/Blog/IndividualBlogPost/IndividualBlogPost190'; // Protocol for Ending Marriage in Sunni Islam
import IndividualBlogPost191 from '../component/Blog/IndividualBlogPost/IndividualBlogPost191'; // Sunni Couple Conflict Resolution
import IndividualBlogPost192 from '../component/Blog/IndividualBlogPost/IndividualBlogPost192'; // Emotional Support in Sunni Marriage
import IndividualBlogPost193 from '../component/Blog/IndividualBlogPost/IndividualBlogPost193'; // Wife's Right to Mahr in Sunni Islam
import IndividualBlogPost194 from '../component/Blog/IndividualBlogPost/IndividualBlogPost194'; // How Marriage Registration Should Be Completed
import IndividualBlogPost195 from '../component/Blog/IndividualBlogPost/IndividualBlogPost195'; // How Property Should Be Divided in Sunni Divorce
import IndividualBlogPost196 from '../component/Blog/IndividualBlogPost/IndividualBlogPost196'; // Acceptable Reasons for Divorce in Sunni Islam
import IndividualBlogPost197 from '../component/Blog/IndividualBlogPost/IndividualBlogPost197'; // Respect in Sunni Marriage
import IndividualBlogPost198 from '../component/Blog/IndividualBlogPost/IndividualBlogPost198'; // Role of Mediation in Sunni Divorce
import IndividualBlogPost199 from '../component/Blog/IndividualBlogPost/IndividualBlogPost199'; // Age Difference in Sunni Marriage
import IndividualBlogPost200 from '../component/Blog/IndividualBlogPost/IndividualBlogPost200'; // Can Sunni Couple Marry After Converting to Islam?
import IndividualBlogPost201 from '../component/Blog/IndividualBlogPost/IndividualBlogPost201'; // How Wali Should Counsel Bride in Sunni Islam
import IndividualBlogPost202 from '../component/Blog/IndividualBlogPost/IndividualBlogPost202'; // Family Honor in Sunni Marriage
import IndividualBlogPost203 from '../component/Blog/IndividualBlogPost/IndividualBlogPost203'; // Cultural Practices in Sunni Marriage
import IndividualBlogPost204 from '../component/Blog/IndividualBlogPost/IndividualBlogPost204'; // Ruling on Delaying Marriage in Sunni Islam
import IndividualBlogPost205 from '../component/Blog/IndividualBlogPost/IndividualBlogPost205'; // Marriage Proposal Decline in Sunni Islam
import IndividualBlogPost206 from '../component/Blog/IndividualBlogPost/IndividualBlogPost206'; // How Marriage Should Be Discussed with Children in Sunni Islam
import IndividualBlogPost207 from '../component/Blog/IndividualBlogPost/IndividualBlogPost207'; // Marrying Cousin in Sunni Islam
import IndividualBlogPost208 from '../component/Blog/IndividualBlogPost/IndividualBlogPost208'; // Anger Management in Sunni Marriage
import IndividualBlogPost209 from '../component/Blog/IndividualBlogPost/IndividualBlogPost209'; // Sunni View on Remarriage After Divorce
import IndividualBlogPost210 from '../component/Blog/IndividualBlogPost/IndividualBlogPost210'; // Husband Providing for Wife in Sunni Islam
import IndividualBlogPost211 from '../component/Blog/IndividualBlogPost/IndividualBlogPost211'; // Iddah Rules in Sunni Islam



import BGImg from '../assets/images/bread_crumb_bg.png';  // Default image

const Routing = () => {
    const [homepage, sethomepage] = useState(false);
    const [footerpage, setfooterpage] = useState(false);
    const location = useLocation();

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Manage Navbar, Footer, and Breadcrumb visibility and default settings
    useEffect(() => {
      if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
        sethomepage(false);
        setfooterpage(false);
      } else {
        sethomepage(true);
        setfooterpage(true);
      }
    }, [location]);

    const [footer, setfooter] = useState({ f1: true });
    const [navbar, setnavbar] = useState({ n2: true });
    const [brdcum, setbrdcum] = useState({
        n1: true,
        bgimg: BGImg // Default breadcrumb background image
    });

    return (
        <>
            {homepage && <Navbar navbar={navbar} />}
            <Routes>
                <Route path="/" element={<Home setfooter={setfooter} setnavbar={setnavbar} setbrdcum={setbrdcum} />} />
                <Route path="/review" element={<Review setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/contact" element={<Contact setfooter={setfooter} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/faq" element={<Faq brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/blog" element={<Blog setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/blog2" element={<Blog2 setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/blog3" element={<Blog3 setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/blog4" element={<Blog4 setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/blog5" element={<Blog5 setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/blog6" element={<Blog6 setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/the-best-muslim-marriage-app" element={<FeaturedBlogPost setnavbar={setnavbar} />} />

                {/* Individual Blog Post Routes */}
                <Route path="/why-marriage-is-good-in-islam" element={<IndividualBlogPost setnavbar={setnavbar} />} />
                <Route path="/top-5-reasons-muslima-women-choose-halal-dating" element={<IndividualBlogPost1 setnavbar={setnavbar} />} />
                <Route path="/empowering-muslim-women-redefining-marriage" element={<IndividualBlogPost2 setnavbar={setnavbar} />} />
                <Route path="/muslimas-compatible-matches-live-marriage" element={<IndividualBlogPost3 setnavbar={setnavbar} />} />
                <Route path="/muslimas-online-marriage-tips" element={<IndividualBlogPost4 setnavbar={setnavbar} />} />
                <Route path="/what-is-nikkah" element={<IndividualBlogPost5 setnavbar={setnavbar} />} />
                <Route path="/find-perfect-nikkah-match-online" element={<IndividualBlogPost6 setnavbar={setnavbar} />} />
                <Route path="/nikkah-in-modern-times" element={<IndividualBlogPost7 setnavbar={setnavbar} />} />
                <Route path="/preparing-for-nikkah" element={<IndividualBlogPost8 setnavbar={setnavbar} />} />
                <Route path="/nikkah-tips-for-busy-professionals" element={<IndividualBlogPost9 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-made-easy" element={<IndividualBlogPost10 setnavbar={setnavbar} />} />
                <Route path="/why-muslim-marriage-apps-are-the-future" element={<IndividualBlogPost11 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-expectations" element={<IndividualBlogPost12 setnavbar={setnavbar} />} />
                <Route path="/qualities-in-a-muslim-marriage-partner" element={<IndividualBlogPost13 setnavbar={setnavbar} />} />
                <Route path="/what-is-halal-dating" element={<IndividualBlogPost14 setnavbar={setnavbar} />} />
                <Route path="/how-to-keep-your-relationship-halal-while-dating-online" element={<IndividualBlogPost15 setnavbar={setnavbar} />} />
                <Route path="/keeping-relationships-halal-while-dating" element={<IndividualBlogPost16 setnavbar={setnavbar} />} />
                <Route path="/halal-dating-vs-traditional-dating" element={<IndividualBlogPost17 setnavbar={setnavbar} />} />
                <Route path="/muslim-dating-apps-finding-love" element={<IndividualBlogPost18 setnavbar={setnavbar} />} />
                <Route path="/live-marriage-future-of-dating-apps" element={<IndividualBlogPost19 setnavbar={setnavbar} />} />

                {/* New Individual Blog Post Routes */}
                <Route path="/nikah-guide" element={<IndividualBlogPost20 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-reddit" element={<IndividualBlogPost21 setnavbar={setnavbar} />} />
                <Route path="/islamic-marriage-rules" element={<IndividualBlogPost22 setnavbar={setnavbar} />} />
                <Route path="/muslim-universal-marriage-bureau" element={<IndividualBlogPost23 setnavbar={setnavbar} />} />
                <Route path="/muslim-wedding-contract" element={<IndividualBlogPost24 setnavbar={setnavbar} />} />
                <Route path="/muslim-wedding-rules" element={<IndividualBlogPost25 setnavbar={setnavbar} />} />
                <Route path="/islam-and-marriage" element={<IndividualBlogPost26 setnavbar={setnavbar} />} />
                <Route path="/temporary-marriage-in-islam" element={<IndividualBlogPost27 setnavbar={setnavbar} />} />
                <Route path="/christian-and-muslim-marriage" element={<IndividualBlogPost28 setnavbar={setnavbar} />} />
                <Route path="/islamic-nikah" element={<IndividualBlogPost29 setnavbar={setnavbar} />} />
                <Route path="/nikah-marriage" element={<IndividualBlogPost30 setnavbar={setnavbar} />} />
                <Route path="/muslim-temporary-marriage" element={<IndividualBlogPost31 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-contract" element={<IndividualBlogPost32 setnavbar={setnavbar} />} />
                <Route path="/islamic-matrimonial-site" element={<IndividualBlogPost33 setnavbar={setnavbar} />} />
                <Route path="/reddit-islam-marriage" element={<IndividualBlogPost34 setnavbar={setnavbar} />} />
                <Route path="/islamic-wedding" element={<IndividualBlogPost35 setnavbar={setnavbar} />} />
                <Route path="/nikah-ceremony" element={<IndividualBlogPost36 setnavbar={setnavbar} />} />
                <Route path="/half-our-deen" element={<IndividualBlogPost37 setnavbar={setnavbar} />} />
                <Route path="/mutah-marriage" element={<IndividualBlogPost38 setnavbar={setnavbar} />} />
                <Route path="/muslim-wedding" element={<IndividualBlogPost39 setnavbar={setnavbar} />} />
                <Route path="/reddit-muslim-marriage" element={<IndividualBlogPost40 setnavbar={setnavbar} />} />
                <Route path="/simple-nikah" element={<IndividualBlogPost41 setnavbar={setnavbar} />} />
                <Route path="/muslim-traditional-wedding" element={<IndividualBlogPost42 setnavbar={setnavbar} />} />
		            <Route path="/muslim-marriage-sites" element={<IndividualBlogPost43 setnavbar={setnavbar} />} />


                <Route path="/islamic-marriage-contract" element={<IndividualBlogPost44 setnavbar={setnavbar} />} />
                <Route path="/arabic-marriage" element={<IndividualBlogPost45 setnavbar={setnavbar} />} />
                <Route path="/understanding-islamic-wedding-contract" element={<IndividualBlogPost46 setnavbar={setnavbar} />} />
                <Route path="/islamic-nikkah" element={<IndividualBlogPost47 setnavbar={setnavbar} />} />
                <Route path="/islamic-wedding-rules" element={<IndividualBlogPost48 setnavbar={setnavbar} />} />
                <Route path="/islamic-marriage-ceremony-nikah" element={<IndividualBlogPost49 setnavbar={setnavbar} />} />
                <Route path="/islam-wedding-ceremony" element={<IndividualBlogPost50 setnavbar={setnavbar} />} />
                <Route path="/understanding-marriage-in-islam" element={<IndividualBlogPost51 setnavbar={setnavbar} />} />
                <Route path="/islam-marriage-ceremony" element={<IndividualBlogPost52 setnavbar={setnavbar} />} />
                <Route path="/islam-and-cousin-marriage" element={<IndividualBlogPost53 setnavbar={setnavbar} />} />
                <Route path="/marriage-according-to-islam" element={<IndividualBlogPost54 setnavbar={setnavbar} />} />
                <Route path="/marrying-a-christian-in-islam" element={<IndividualBlogPost55 setnavbar={setnavbar} />} />
                <Route path="/multiple-marriage-in-islam" element={<IndividualBlogPost56 setnavbar={setnavbar} />} />
                <Route path="/multiple-wives-in-islam" element={<IndividualBlogPost57 setnavbar={setnavbar} />} />
                <Route path="/muslim-and-marriage" element={<IndividualBlogPost58 setnavbar={setnavbar} />} />
                <Route path="/cousin-marriage-in-islam" element={<IndividualBlogPost59 setnavbar={setnavbar} />} />
                <Route path="/muslims-can-marry-christians" element={<IndividualBlogPost60 setnavbar={setnavbar} />} />
                <Route path="/muslim-cousin-marriage" element={<IndividualBlogPost61 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-ceremony" element={<IndividualBlogPost62 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-is-a-contract" element={<IndividualBlogPost63 setnavbar={setnavbar} />} />
                <Route path="/muslim-religion-marriage" element={<IndividualBlogPost64 setnavbar={setnavbar} />} />
                <Route path="/muslim-religion-marriage-rules" element={<IndividualBlogPost65 setnavbar={setnavbar} />} />
                <Route path="/muslim-marriage-rules" element={<IndividualBlogPost66 setnavbar={setnavbar} />} />
                <Route path="/muslim-wedding-ceremony" element={<IndividualBlogPost67 setnavbar={setnavbar} />} />
                <Route path="/traditional-islamic-wedding" element={<IndividualBlogPost68 setnavbar={setnavbar} />} />
                <Route path="/muslim-matrimony" element={<IndividualBlogPost69 setnavbar={setnavbar} />} />
                <Route path="/temporary-nikah-in-islam" element={<IndividualBlogPost70 setnavbar={setnavbar} />} />
                <Route path="/islamic-dating" element={<IndividualBlogPost71 setnavbar={setnavbar} />} />
                <Route path="/muslim-matrimonial-websites" element={<IndividualBlogPost72 setnavbar={setnavbar} />} />
                <Route path="/muslim-4-marriage" element={<IndividualBlogPost73 setnavbar={setnavbar} />} />
                <Route path="/muslima-dating" element={<IndividualBlogPost74 setnavbar={setnavbar} />} />
                <Route path="/islamic-marriage-sites" element={<IndividualBlogPost75 setnavbar={setnavbar} />} />
                <Route path="/islamic-marriage-website" element={<IndividualBlogPost76 setnavbar={setnavbar} />} />
                <Route path="/exploring-muslim-marriage-sites" element={<IndividualBlogPost77 setnavbar={setnavbar} />} />
                <Route path="/muslim-dating" element={<IndividualBlogPost78 setnavbar={setnavbar} />} />
                <Route path="/the-ultimate-guide-to-muslim-marriage-websites" element={<IndividualBlogPost79 setnavbar={setnavbar} />} />
                <Route path="/best-muslim-dating-apps" element={<IndividualBlogPost80 setnavbar={setnavbar} />} />
                <Route path="/marriage-in-islam" element={<IndividualBlogPost81 setnavbar={setnavbar} />} />
                <Route path="/islamic-matrimonial-website" element={<IndividualBlogPost82 setnavbar={setnavbar} />} />
                <Route path="/unhappy-marriage-in-islam" element={<IndividualBlogPost83 setnavbar={setnavbar} />} />
                <Route path="/muslim-couple-wedding" element={<IndividualBlogPost84 setnavbar={setnavbar} />} />
                <Route path="/islamic-marriage-ceremony-nikah" element={<IndividualBlogPost85 setnavbar={setnavbar} />} />


                <Route path="/what-is-nikkah" element={<IndividualBlogPost86 setnavbar={setnavbar} />} />
                <Route path="/importance-of-nikah-in-sunni-islam" element={<IndividualBlogPost87 setnavbar={setnavbar} />} />
                <Route path="/significance-of-mahr-in-sunni-nikah" element={<IndividualBlogPost88 setnavbar={setnavbar} />} />
                <Route path="/sunni-muslim-interfaith-marriage" element={<IndividualBlogPost89 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-muslims-marry-people-of-other-faiths" element={<IndividualBlogPost90 setnavbar={setnavbar} />} />
                <Route path="/wali-guardian-role-in-sunni-marriage" element={<IndividualBlogPost91 setnavbar={setnavbar} />} />
                <Route path="/marriage-proposal-in-sunni-culture" element={<IndividualBlogPost92 setnavbar={setnavbar} />} />
                <Route path="/nikah-walima-difference" element={<IndividualBlogPost93 setnavbar={setnavbar} />} />
                <Route path="/how-marriage-ceremony-is-conducted-in-sunni-islam" element={<IndividualBlogPost94 setnavbar={setnavbar} />} />
                <Route path="/witnesses-in-sunni-nikah" element={<IndividualBlogPost95 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-muslims-have-civil-marriage" element={<IndividualBlogPost96 setnavbar={setnavbar} />} />
                <Route path="/how-mahr-should-be-set-in-sunni-marriage" element={<IndividualBlogPost97 setnavbar={setnavbar} />} />
                <Route path="/conditions-for-valid-nikah" element={<IndividualBlogPost98 setnavbar={setnavbar} />} />
                <Route path="/is-forced-marriage-allowed-in-sunni-islam" element={<IndividualBlogPost99 setnavbar={setnavbar} />} />
                <Route path="/sunni-islam-divorce-process" element={<IndividualBlogPost100 setnavbar={setnavbar} />} />
                <Route path="/sunni-woman-proposing-marriage" element={<IndividualBlogPost101 setnavbar={setnavbar} />} />
                <Route path="/types-of-mahr-in-sunni-islam" element={<IndividualBlogPost102 setnavbar={setnavbar} />} />
                <Route path="/cousin-marriage-in-sunni-islam" element={<IndividualBlogPost103 setnavbar={setnavbar} />} />
                <Route path="/how-couples-interact-before-marriage" element={<IndividualBlogPost104 setnavbar={setnavbar} />} />
                <Route path="/role-of-istikhara-in-sunni-marriage" element={<IndividualBlogPost105 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-muslims-marry-shia-muslims" element={<IndividualBlogPost106 setnavbar={setnavbar} />} />
                <Route path="/premarital-relationships-in-sunni-islam" element={<IndividualBlogPost107 setnavbar={setnavbar} />} />
                <Route path="/role-of-family-in-sunni-marriage" element={<IndividualBlogPost108 setnavbar={setnavbar} />} />
                <Route path="/how-sunni-islam-views-polygamy" element={<IndividualBlogPost109 setnavbar={setnavbar} />} />
                <Route path="/sunni-marriage-ceremony" element={<IndividualBlogPost110 setnavbar={setnavbar} />} />
                <Route path="/witnesses-in-sunni-nikah" element={<IndividualBlogPost111 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-muslims-have-civil-marriage" element={<IndividualBlogPost112 setnavbar={setnavbar} />} />
                <Route path="/how-mahr-is-set-in-sunni-marriage" element={<IndividualBlogPost113 setnavbar={setnavbar} />} />
                <Route path="/valid-sunni-nikah-conditions" element={<IndividualBlogPost114 setnavbar={setnavbar} />} />
                <Route path="/is-forced-marriage-allowed-in-sunni-islam" element={<IndividualBlogPost115 setnavbar={setnavbar} />} />
                <Route path="/how-sunni-islam-addresses-divorce" element={<IndividualBlogPost116 setnavbar={setnavbar} />} />
                <Route path="/sunni-woman-proposing-marriage" element={<IndividualBlogPost117 setnavbar={setnavbar} />} />
                <Route path="/types-of-mahr-in-sunni-islam" element={<IndividualBlogPost118 setnavbar={setnavbar} />} />
                <Route path="/cousin-marriage-in-sunni-islam" element={<IndividualBlogPost119 setnavbar={setnavbar} />} />
                <Route path="/how-should-couples-interact-before-marriage" element={<IndividualBlogPost120 setnavbar={setnavbar} />} />
                <Route path="/sunni-woman-and-wali-in-marriage" element={<IndividualBlogPost121 setnavbar={setnavbar} />} />
                <Route path="/rights-of-wife-in-sunni-islam" element={<IndividualBlogPost122 setnavbar={setnavbar} />} />
                <Route path="/rights-of-husband-in-sunni-islam" element={<IndividualBlogPost123 setnavbar={setnavbar} />} />
                <Route path="/sunni-marriage-contract" element={<IndividualBlogPost124 setnavbar={setnavbar} />} />
                <Route path="/sunni-marriage-contract-conditions" element={<IndividualBlogPost125 setnavbar={setnavbar} />} />
                <Route path="/divorce-in-sunni-islam" element={<IndividualBlogPost126 setnavbar={setnavbar} />} />
                <Route path="/what-is-iddah-in-sunni-islam" element={<IndividualBlogPost127 setnavbar={setnavbar} />} />
                <Route path="/divorce-in-sunni-islam" element={<IndividualBlogPost128 setnavbar={setnavbar} />} />
                <Route path="/marriage-annulment-in-sunni-islam" element={<IndividualBlogPost129 setnavbar={setnavbar} />} />
                <Route path="/sunni-view-on-marriage-with-non-muslim" element={<IndividualBlogPost130 setnavbar={setnavbar} />} />
                <Route path="/duties-of-husband-in-sunni-islam" element={<IndividualBlogPost131 setnavbar={setnavbar} />} />
                <Route path="/rights-of-wife-in-sunni-islam" element={<IndividualBlogPost132 setnavbar={setnavbar} />} />
                <Route path="/how-sunni-islam-addresses-domestic-responsibilities" element={<IndividualBlogPost133 setnavbar={setnavbar} />} />
                <Route path="/wali-role-in-sunni-marriage" element={<IndividualBlogPost134 setnavbar={setnavbar} />} />
                <Route path="/walima-in-sunni-islam" element={<IndividualBlogPost135 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-couple-get-engaged-without-family-approval" element={<IndividualBlogPost136 setnavbar={setnavbar} />} />
                <Route path="/role-of-consent-in-sunni-marriage" element={<IndividualBlogPost138 setnavbar={setnavbar} />} />
                <Route path="/premarital-counseling-in-sunni-islam" element={<IndividualBlogPost139 setnavbar={setnavbar} />} />
                <Route path="/iddah-observance-rules" element={<IndividualBlogPost140 setnavbar={setnavbar} />} />
                <Route path="/child-custody-in-sunni-islam" element={<IndividualBlogPost141 setnavbar={setnavbar} />} />
                <Route path="/inheritance-rights-in-marriage" element={<IndividualBlogPost142 setnavbar={setnavbar} />} />
                <Route path="/sunni-woman-remarrying" element={<IndividualBlogPost143 setnavbar={setnavbar} />} />
                <Route path="/sunni-islam-second-marriage" element={<IndividualBlogPost144 setnavbar={setnavbar} />} />
                <Route path="/proper-age-for-marriage-in-sunni-islam" element={<IndividualBlogPost145 setnavbar={setnavbar} />} />
                <Route path="/marital-discord-resolution-in-sunni-islam" element={<IndividualBlogPost146 setnavbar={setnavbar} />} />
                <Route path="/obligations-of-parents-in-sunni-wedding" element={<IndividualBlogPost147 setnavbar={setnavbar} />} />
                <Route path="/family-planning-in-sunni-islam" element={<IndividualBlogPost148 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-husband-restrict-travel" element={<IndividualBlogPost149 setnavbar={setnavbar} />} />
                <Route path="/prenuptial-agreements-in-sunni-islam" element={<IndividualBlogPost150 setnavbar={setnavbar} />} />
                <Route path="/healthy-muslim-couple" element={<IndividualBlogPost151 setnavbar={setnavbar} />} />
                <Route path="/sunni-woman-working-outside-home" element={<IndividualBlogPost152 setnavbar={setnavbar} />} />
                <Route path="/inheritance-between-spouses-in-sunni-islam" element={<IndividualBlogPost153 setnavbar={setnavbar} />} />
                <Route path="/sunni-wedding-ceremony-requirements" element={<IndividualBlogPost154 setnavbar={setnavbar} />} />
                <Route path="/sunni-marriage-preparation" element={<IndividualBlogPost155 setnavbar={setnavbar} />} />
                <Route path="/role-of-wali-in-sunni-nikah" element={<IndividualBlogPost156 setnavbar={setnavbar} />} />
                <Route path="/mahr-in-sunni-marriage" element={<IndividualBlogPost157 setnavbar={setnavbar} />} />
                <Route path="/how-nikah-should-be-registered-in-sunni-islam" element={<IndividualBlogPost158 setnavbar={setnavbar} />} />
                <Route path="/sunni-view-on-romantic-love-before-marriage" element={<IndividualBlogPost159 setnavbar={setnavbar} />} />
                <Route path="/wedding-expenses-in-sunni-culture" element={<IndividualBlogPost160 setnavbar={setnavbar} />} />
                <Route path="/purpose-of-marriage-in-sunni-islam" element={<IndividualBlogPost161 setnavbar={setnavbar} />} />
                <Route path="/sunni-nikah-witnesses" element={<IndividualBlogPost162 setnavbar={setnavbar} />} />
                <Route path="/marriage-strengthening-faith" element={<IndividualBlogPost163 setnavbar={setnavbar} />} />
                <Route path="/sunni-islam-dowry-jahaz" element={<IndividualBlogPost164 setnavbar={setnavbar} />} />
                <Route path="/halal-dating-in-sunni-islam" element={<IndividualBlogPost165 setnavbar={setnavbar} />} />
                <Route path="/marriage-proposals-in-sunni-culture" element={<IndividualBlogPost166 setnavbar={setnavbar} />} />
                <Route path="/sunni-couple-marriage-without-family-involvement" element={<IndividualBlogPost167 setnavbar={setnavbar} />} />
                <Route path="/how-does-sunni-islam-view-marriage-to-a-revert" element={<IndividualBlogPost168 setnavbar={setnavbar} />} />
                <Route path="/sunni-couple-finances" element={<IndividualBlogPost169 setnavbar={setnavbar} />} />
                <Route path="/premarital-education-in-sunni-islam" element={<IndividualBlogPost170 setnavbar={setnavbar} />} />
                <Route path="/divorce-finalization-in-sunni-islam" element={<IndividualBlogPost171 setnavbar={setnavbar} />} />
                <Route path="/khula-in-sunni-islam" element={<IndividualBlogPost172 setnavbar={setnavbar} />} />

                <Route path="/child-support-in-sunni-divorce" element={<IndividualBlogPost174 setnavbar={setnavbar} />} />
                <Route path="/significance-of-walima-in-sunni-islam" element={<IndividualBlogPost175 setnavbar={setnavbar} />} />
                <Route path="/sunni-wedding-invitation-etiquette" element={<IndividualBlogPost176 setnavbar={setnavbar} />} />
                <Route path="/sunni-couple-family-boundaries" element={<IndividualBlogPost177 setnavbar={setnavbar} />} />
                <Route path="/honesty-in-sunni-marriage" element={<IndividualBlogPost178 setnavbar={setnavbar} />} />
                <Route path="/marriage-disputes-in-sunni-culture" element={<IndividualBlogPost179 setnavbar={setnavbar} />} />
                <Route path="/wali-responsibility-in-sunni-islam" element={<IndividualBlogPost180 setnavbar={setnavbar} />} />
                <Route path="/marriage-gifts-in-sunni-wedding" element={<IndividualBlogPost182 setnavbar={setnavbar} />} />
                <Route path="/financial-transparency-in-marriage" element={<IndividualBlogPost183 setnavbar={setnavbar} />} />
                <Route path="/sunni-couple-living-separately" element={<IndividualBlogPost184 setnavbar={setnavbar} />} />
                <Route path="/modesty-in-islam" element={<IndividualBlogPost185 setnavbar={setnavbar} />} />
                <Route path="/benefits-of-marriage-in-islam" element={<IndividualBlogPost186 setnavbar={setnavbar} />} />
                <Route path="/parental-involvement-in-sunni-marriage" element={<IndividualBlogPost187 setnavbar={setnavbar} />} />
                <Route path="/role-of-extended-family-in-sunni-marriage" element={<IndividualBlogPost188 setnavbar={setnavbar} />} />
                <Route path="/widow-remarriage-in-sunni-islam" element={<IndividualBlogPost189 setnavbar={setnavbar} />} />
                <Route path="/protocol-for-ending-marriage-in-sunni-islam" element={<IndividualBlogPost190 setnavbar={setnavbar} />} />
                <Route path="/sunni-couple-conflict-resolution" element={<IndividualBlogPost191 setnavbar={setnavbar} />} />
                <Route path="/emotional-support-in-sunni-marriage" element={<IndividualBlogPost192 setnavbar={setnavbar} />} />
                <Route path="/wifes-right-to-mahr-in-sunni-islam" element={<IndividualBlogPost193 setnavbar={setnavbar} />} />
                <Route path="/how-should-nikah-be-registered-in-sunni-islam" element={<IndividualBlogPost194 setnavbar={setnavbar} />} />
                <Route path="/how-property-should-be-divided-in-sunni-divorce" element={<IndividualBlogPost195 setnavbar={setnavbar} />} />
                <Route path="/acceptable-reasons-for-divorce-in-sunni-islam" element={<IndividualBlogPost196 setnavbar={setnavbar} />} />
                <Route path="/respect-in-sunni-marriage" element={<IndividualBlogPost197 setnavbar={setnavbar} />} />
                <Route path="/role-of-mediation-in-sunni-divorce" element={<IndividualBlogPost198 setnavbar={setnavbar} />} />
                <Route path="/age-difference-in-sunni-marriage" element={<IndividualBlogPost199 setnavbar={setnavbar} />} />
                <Route path="/can-sunni-couple-marry-after-converting-to-islam" element={<IndividualBlogPost200 setnavbar={setnavbar} />} />
                <Route path="/how-wali-should-counsel-bride-in-sunni-islam" element={<IndividualBlogPost201 setnavbar={setnavbar} />} />
                <Route path="/family-honor-in-sunni-marriage" element={<IndividualBlogPost202 setnavbar={setnavbar} />} />
                <Route path="/cultural-practices-in-sunni-marriage" element={<IndividualBlogPost203 setnavbar={setnavbar} />} />
                <Route path="/ruling-on-delaying-marriage-in-sunni-islam" element={<IndividualBlogPost204 setnavbar={setnavbar} />} />
                <Route path="/marriage-proposal-decline-in-sunni-islam" element={<IndividualBlogPost205 setnavbar={setnavbar} />} />
                <Route path="/how-marriage-should-be-discussed-with-children-in-sunni-islam" element={<IndividualBlogPost206 setnavbar={setnavbar} />} />
                <Route path="/marrying-cousin-in-sunni-islam" element={<IndividualBlogPost207 setnavbar={setnavbar} />} />
                <Route path="/anger-management-in-sunni-marriage" element={<IndividualBlogPost208 setnavbar={setnavbar} />} />
                <Route path="/sunni-view-on-remarriage-after-divorce" element={<IndividualBlogPost209 setnavbar={setnavbar} />} />
                <Route path="/husband-providing-for-wife-in-sunni-islam" element={<IndividualBlogPost210 setnavbar={setnavbar} />} />
                <Route path="/iddah-rules-in-sunni-islam" element={<IndividualBlogPost211 setnavbar={setnavbar} />} />




                <Route path="/pricing" element={<Pricing setnavbar={setnavbar} brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/terms-of-service" element={<TermsOfService brdcum={{ b1: true, bgimg: BGImg }} />} />
                <Route path="/cookie-policy" element={<CookiePolicy brdcum={{ b1: true, bgimg: BGImg }} />} />
            </Routes>
            {footerpage && <Footer footer={footer} />}
        </>
    );
};

export default Routing;
