import React from 'react';
import { Link } from 'react-router-dom';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/nikah_marriage_ceremony.png"; // Renamed image

function NikahMarriage() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Nikah Marriage in Islam | A Sacred Bond</title>
                <meta name="description" content="Explore the significance of Nikah in Islam, its role in establishing a strong, spiritual bond, and its importance in building a family-oriented society." />
                <meta name="keywords" content="Nikah, Islamic Marriage, Muslim Wedding, Marriage in Islam, Islam, Family, Faith, Islamic Traditions" />
                <meta property="og:title" content="Nikah Marriage in Islam | A Sacred Bond" />
                <meta property="og:description" content="Learn the principles of Nikah, a blessed union in Islam, and its spiritual and social impact on the Muslim community." />
                <meta property="og:image" content="/path/to/nikah-marriage-share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/nikah-marriage" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                <div className="container">
                    <div className="bred_text">
                        <h1>Blog</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span>»</span></li>
                            <li><Link to="/blog">Blogs</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Traditions</span>
                            <span>October 22, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>Nikah Marriage in Islam</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Nikah Marriage Ceremony" />
                        </div>
                        <div className="info">
                            <p>In Islam, Nikah is not just a legal contract but a deeply spiritual and social covenant. It represents the union of two souls, brought together by Allah�s will, to form a family under His guidance. The institution of Nikah holds a central place in the lives of Muslims, helping to foster relationships based on love, trust, and compassion.</p>

                            <p>The Prophet Muhammad (PBUH) emphasized the importance of Nikah by stating, "Marriage is my Sunnah. Whoever turns away from my Sunnah is not from me" (Sahih Bukhari). This hadith reinforces how essential marriage is within Islamic tradition, not only as a personal milestone but also as a collective moral and social responsibility.</p>

                            <h3>The Spiritual Meaning of Nikah</h3>
                            <p>Nikah, in its essence, is an act of worship and a means of fulfilling a Muslim's faith. It symbolizes mutual support between spouses, who help each other grow closer to Allah. As the Quran states, "And among His signs is this, that He created for you mates from among yourselves, that you may dwell in tranquility with them, and He has put love and mercy between your hearts" (Quran 30:21). This verse emphasizes that marriage brings serenity and love, qualities that are necessary for spiritual and emotional growth.</p>

                            <p>The idea of Nikah being a �complete half of faith� speaks to its role in guarding against temptations and ensuring one remains on the path of righteousness. The Quran also highlights the protective nature of marriage by describing spouses as garments for one another, illustrating their roles in safeguarding each other's dignity and modesty.</p>

                            <h3>Social Impact of Nikah in Islam</h3>
                            <p>Beyond personal fulfillment, Nikah plays a key role in the broader Islamic community. It establishes families, which are the core of Islamic society. Strong, family-centered relationships foster stability, emotional support, and a sense of responsibility. Through Nikah, individuals learn the virtues of sacrifice, patience, and mutual respect, which are essential to maintaining a peaceful and moral society.</p>

                            <p>Nikah also serves to regulate societal interactions by offering a lawful means to fulfill natural desires, thus preventing immoral behaviors like fornication and adultery. By encouraging early marriage and promoting modesty, Islam seeks to protect the dignity of individuals and promote healthy social relations.</p>

                            <h3>Essentials of a Valid Nikah</h3>
                            <p>For a Nikah to be valid, it must fulfill several key conditions according to Islamic jurisprudence. The consent of both parties is paramount, as forced marriages are strictly prohibited in Islam. In addition, there must be witnesses to the marriage contract, and the bride should receive a Mahr (a marital gift) from the groom, symbolizing his commitment and responsibility towards her.</p>

                            <p>The mutual consent and the presence of witnesses highlight the transparent and public nature of the Nikah, ensuring that both families and society recognize the legitimacy and sanctity of the union. The Mahr, on the other hand, serves as a token of respect and as an assurance of the husband's duty to support and care for his wife.</p>

                            <h3>Rights and Responsibilities in Nikah</h3>
                            <p>Islam sets clear guidelines for the rights and responsibilities of both spouses in marriage. The Quran teaches that men and women are equal partners with complementary roles. As it says, "And women shall have rights similar to the rights against them, according to what is equitable" (Quran 2:228). This highlights the mutual obligations of fairness and kindness that are fundamental to a successful marriage.</p>

                            <p>While the husband is tasked with providing for the family, the wife�s role, though traditionally centered on the home, is equally important in shaping the moral and emotional environment of the household. In today�s context, Islam acknowledges that both spouses can contribute in various ways to the family, supporting each other through shared responsibilities.</p>

                            <ul>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Nikah provides emotional stability and spiritual fulfillment.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> It safeguards individuals from sinful behavior.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Nikah is the foundation of a stable family and society.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Spouses are partners in faith, guiding each other towards Allah.</p></li>
                            </ul>
                        </div>

                        <div className="quote_block">
                            <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                            <h2>"Marriage is half of faith."</h2>
                            <p><span className="name">Prophet Muhammad (PBUH),</span> Sahih Muslim</p>
                        </div>

                        <p>In conclusion, Nikah in Islam is much more than a legal arrangement�it is a spiritual and social bond that brings peace, stability, and happiness to individuals and society. Through Nikah, Muslims fulfill their religious duties while building loving, compassionate families that form the backbone of the Muslim community. As an act of worship, Nikah holds the promise of divine blessings and spiritual rewards, guiding couples on a path of faith, love, and mutual respect.</p>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>October 22, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Nikah,</span></li>
                                <li><span>Islam,</span></li>
                                <li><span>Marriage</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NikahMarriage;

