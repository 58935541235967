import React from 'react';
import Bredcrumb from '../Bredcrumb/Main';
import BGImg from "../../assets/images/bread_crumb_bg.png";  // Default background image
import Leave from '../Mencontact/Leave';
import Map from '../Mencontact/Map';

const Main = () => {

    return (
        <>
            {/* Always display the breadcrumb with the default image */}
            <Bredcrumb 
                no={1} 
                title="Contact Us" 
                paragraph="If you have any query, please get in touch with us, we will revert back quickly." 
                tag="Contact us" 
                bgimg={BGImg} 
            />

            {/* Contact form */}
            <Leave />

            {/* Map */}
            <Map />
        </>
    );
}

export default Main;
