import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/rights_of_wife_in_sunni_islam.png"; // Unique image for this article

function RightsOfWifeSunniIslam() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>What are the Rights of a Wife in Sunni Islam? | Islamic Insights</title>
                <meta name="description" content="Understand the rights of a wife in Sunni Islam, including financial support, respect, and the mutual responsibilities within marriage as per Islamic teachings." />
                <meta name="keywords" content="Rights of Wife in Islam, Sunni Marriage, Muslim Matrimonial Rights, Islamic Marriage, Islamic Family" />
                <meta property="og:title" content="What are the Rights of a Wife in Sunni Islam? | Islamic Insights" />
                <meta property="og:description" content="Learn about the rights and duties of a wife in Sunni Islam, focusing on mutual respect, support, and harmony within the marital relationship." />
                <meta property="og:image" content="/path/to/rights-of-wife-share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/rights-of-wife-in-sunni-islam" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="Rights of a Wife in Sunni Islam" 
                paragraph="Discover the essential rights granted to wives in Sunni Islam." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 5, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>What are the Rights of a Wife in Sunni Islam?</h2>
                        </div>
                        <div className="main_img">
                        <img src={MainPic} alt="Rights of a Wife in Sunni Islam" />
                        </div>
                        <div className="info">
                            <p>In Sunni Islam, the rights of a wife are clearly outlined and are intended to ensure her dignity, protection, and well-being within the marriage. These rights encompass financial support, mutual respect, and a compassionate partnership, all rooted in Islamic teachings to promote harmony and fairness in the marital relationship.</p>

                            <h3>Financial Rights: Nafaqah</h3>
                            <p>One of the primary rights of a wife in Sunni Islam is financial support, known as *nafaqah*. The husband is obligated to provide for his wife’s basic needs, including food, clothing, and shelter. This responsibility is not contingent upon the wife’s wealth or her own earnings; it is a duty that the husband must fulfill as part of his role as the provider in the family.</p>

                            <p>The Quran emphasizes this duty in Surah Al-Baqarah (2:233): "The father of the child shall bear the cost of the mother's food and clothing on a reasonable basis." This verse highlights the husband's obligation to ensure his wife lives comfortably and with dignity, reflecting the importance Islam places on the husband’s role as a caretaker and provider.</p>

                            <h3>Respect and Kind Treatment</h3>
                            <p>Respect and kindness are fundamental principles in an Islamic marriage. The Prophet Muhammad (PBUH) said, "The best of you are those who are best to their wives" (Sunan At-Tirmidhi). This hadith underscores the importance of treating one’s wife with kindness, compassion, and respect.</p>

                            <p>A wife has the right to be treated with dignity and not subjected to any form of abuse or mistreatment. This mutual respect helps foster a loving and supportive environment, where both spouses can thrive emotionally and spiritually.</p>

                            <h3>Emotional and Spiritual Support</h3>
                            <p>Marriage in Islam is seen as a partnership where both spouses support each other emotionally and spiritually. The Quran states, "And among His signs is this, that He created for you mates from among yourselves, that you may dwell in tranquility with them, and He has put love and mercy between your hearts" (Quran 30:21).</p>

                            <p>This verse highlights the role of spouses as sources of comfort and tranquility for each other. The spiritual bond in marriage is crucial, as it helps both partners grow in their faith and devotion to Allah. The concept of being each other’s garments (Quran 2:187) further illustrates this intimacy and mutual protection.</p>

                            <h3>Right to Education and Personal Growth</h3>
                            <p>Islam encourages both men and women to seek knowledge. A wife has the right to pursue education and personal growth, which are seen as valuable contributions to her personal development and the well-being of the family. The Prophet Muhammad (PBUH) stated, "Seeking knowledge is an obligation upon every Muslim" (Sunan Ibn Majah).</p>

                            <p>This right ensures that a wife is not only a nurturer and caregiver but also an empowered individual who can contribute intellectually and spiritually to her family and society.</p>

                            <h3>Consultation and Mutual Decision-Making</h3>
                            <p>In Islam, marriage is a partnership built on mutual consultation and decision-making. The Quran advises, "And those who have responded to their lord and established prayer and whose affair is [determined by] consultation among themselves, and from what We have provided them, they spend" (Quran 42:38).</p>

                            <p>This principle of *shura* (consultation) applies within the marriage, encouraging both husband and wife to discuss and make decisions together, fostering a cooperative and harmonious relationship.</p>

                            <h3>Sexual Rights and Mutual Fulfillment</h3>
                            <p>Islam acknowledges the importance of sexual intimacy in marriage and emphasizes mutual fulfillment and consent. The Prophet Muhammad (PBUH) advised, "When one of you has sexual intercourse with his spouse, it is a charity" (Sunan Ibn Majah), highlighting the importance of this intimate aspect of the marital relationship.</p>

                            <p>Both spouses have the right to sexual satisfaction, and this mutual obligation helps strengthen the marital bond and ensures that both partners feel valued and respected.</p>

                            <div className="quote_block">
                                <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                                <h2>"The best of you are those who are best to their wives."</h2>
                                <p><span className="name">Prophet Muhammad (PBUH),</span> Sunan At-Tirmidhi</p>
                            </div>

                            <p>For Muslim singles seeking to build such meaningful and respectful relationships, platforms like MiSoulmate: Muslim Marriage App offer a unique opportunity. This iOS-based app features a 10-minute live matching session, designed to help users quickly connect and determine compatibility. By promoting genuine connections and reducing the prevalence of fake profiles and ghosting, MiSoulmate facilitates the journey towards finding a compatible Muslim partner who shares the same values and aspirations.</p>

                            <p>In conclusion, the rights of a wife in Sunni Islam are designed to ensure her well-being, dignity, and fulfillment within marriage. By fulfilling these rights and responsibilities, both spouses can create a harmonious and balanced relationship, contributing to their personal growth and the overall stability of their family and society.</p>
                        </div>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 5, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Marriage,</span></li>
                                <li><span>Islam,</span></li>
                                <li><span>Rights,</span></li>
                                <li><span>Wife,</span></li>
                                <li><span>Sunni Islam</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RightsOfWifeSunniIslam;
