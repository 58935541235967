import React from 'react'
import { Link } from 'react-router-dom'
import Blogdtls from "../../../assets/images/topratedapp.png"

function FeaturedBlogPost() {
    return (
        <>
            <section className="row_am blog_list_main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" >
                            <div className="blog_img">
                                <img src={Blogdtls} alt="image" />
                                <span>Oct 9, 2024</span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="blog_text">
                                <span className="choice_badge">EDITOR CHOICE</span>
                                <div className="section_title">
                                    <h2>Top rated app of the year!</h2>
                                    <p>MiSoulmate is revolutionizing how Muslims find their life partners with its unique live marriage video call feature. 
                                    In a world where authentic connections are hard to find, MiSoulmate stands out by offering users the ability to match and connect in real-time, with just 10 minutes on a live video call.</p>
                                    <p>Unlike other apps, MiSoulmate provides a platform where users can engage in live conversations with potential matches, allowing them to get to know each other better before making a decision to move forward. This live matching feature ensures that you can have meaningful, face-to-face conversations right from the comfort of your home.</p>
                                    <Link to="/the-best-muslim-marriage-app">READ MORE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeaturedBlogPost