import React from 'react';

function Review() {
    return (
        <>
            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="section_title">
                            <h2>Cookie Policy</h2>
                        </div>
                        <div className="info">
                            <p>
                                This Cookie Policy applies to MiSoulmate Inc., our website at www.misoulmate.com, and our iOS and Android mobile applications (collectively, our "Platform").
                            </p>
                            <p>
                                In this policy, we explain how we use cookies and similar tracking technologies to enhance your experience on our Platform. This Cookie Policy is in line with the following data protection regulations:
                            </p>
                            <ul>
                                <li>Canada's Personal Information Protection and Electronic Documents Act (PIPEDA)</li>
                                <li>Ontario's provincial privacy laws</li>
                                <li>EU’s General Data Protection Regulation (GDPR)</li>
                                <li>California’s Consumer Privacy Act (CCPA)</li>
                                <li>Delaware's Online Privacy and Protection Act (DOPPA)</li>
                            </ul>

                            <h3>What is a Cookie?</h3>
                            <p>
                                A cookie is a small piece of data that is stored on your device when you visit a website. Cookies help us understand user activity on our Platform, enhance user experience, and provide personalized content or ads. Cookies may also help us improve our website performance and services.
                            </p>

                            <h3>How We Use Cookies</h3>
                            <p>
                                We use cookies for several purposes on our Platform, including:
                            </p>
                            <ul>
                                <li><strong>Essential Cookies:</strong> These cookies are necessary for the functioning of our website. They enable features such as navigation and access to secure areas of the website.</li>
                                <li><strong>Performance and Analytics Cookies:</strong> These cookies help us measure traffic to our site and analyze how users interact with the Platform. We use these insights to improve the functionality and content of the website.</li>
                                <li><strong>Functional Cookies:</strong> These cookies enable enhanced features like remembering user preferences, such as language settings or login details, for a more personalized experience.</li>
                                <li><strong>Targeting/Advertising Cookies:</strong> These cookies help us provide advertisements that are more relevant to your interests. They may also be used by our advertising partners to profile your interests and show relevant ads on other websites.</li>
                            </ul>

                            <h3>Cookies Used on Our Platform</h3>
                            <p>
                                The cookies used on our Platform are designed to improve your experience and are categorized as follows:
                            </p>
                            <ul>
                                <li>
                                    <strong>Google Analytics Cookies:</strong> We use Google Analytics to analyze user behavior on our Platform. The data collected is anonymized and used to optimize user experience. Google Analytics sets the following cookies:
                                    <ul>
                                        <li><strong>_utma:</strong> Tracks user visits and generates a unique user ID.</li>
                                        <li><strong>_utmb and _utmc:</strong> Used to track the session length of users on our site.</li>
                                        <li><strong>_utmz:</strong> Tracks the source of traffic, whether through search engines, ads, or direct access.</li>
                                        <li><strong>_ga and _ga_DVL3QM90C3:</strong> Collect data on user interactions with the site for statistical purposes.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                The data collected through Google Analytics may be transmitted to and stored on servers in the United States. However, Google adheres to the data protection guidelines specified by PIPEDA and GDPR. You can opt out of data collection via Google Analytics using this link: <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out</a>.
                            </p>

                            <h3>Managing Cookies</h3>
                            <p>
                                When you first visit our Platform, you will be presented with an option to accept or decline cookies. You can manage your cookie preferences by adjusting your browser settings to either accept all cookies, block certain types of cookies, or notify you when cookies are being used.
                            </p>
                            <p>
                                If you choose to block certain cookies, your experience on the Platform may be impacted, and some features may not work as intended. However, you will still be able to access and use the Platform.
                            </p>

                            <h3>Do-Not-Track (DNT) Signals</h3>
                            <p>
                                Many web browsers offer a Do-Not-Track (DNT) feature that allows you to signal your preferences about online tracking. However, at present, there is no consistent standard for DNT signals, and as a result, we do not respond to DNT browser settings. If a standard is adopted in the future, we will modify our practices accordingly.
                            </p>

                            <h3>Updates to Our Cookie Policy</h3>
                            <p>
                                We may update this Cookie Policy from time to time to reflect changes in our practices or regulatory requirements. The latest version of the Cookie Policy will be posted on this page, and any significant changes will be communicated to you through the Platform or email.
                            </p>

                            <p><strong>Effective Date:</strong> Sept 9th, 2024</p>

                            <h3>Contact Us</h3>
                            <p>
                                If you have any questions about our use of cookies or how we handle your data, feel free to contact us at support@misoulmate.com.
                            </p>
                        </div>

                        <div className="quote_block">
                            <h2>"Your privacy and cookie preferences are important to us, and we are committed to transparency."</h2>
                            <p><span className="name">MiSoulmate Inc.,</span> Data Protection Team</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Review;
