import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo1 from "../../assets/images/logo.png";

const Main = ({ navbar }) => {
  // const location = useLocation();
  // const path = location.pathname;

  const [scrolled, setScrolled] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobile(!mobile);
  };

  return (
    <header className={`header ${scrolled ? 'fix_style fixed' : ''}`}>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link to="/" className="navbar-brand">
            <img src={logo1} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileMenu}
          >
            <div className={`toggle-wrap ${mobile && "active"}`}>
              <span className="toggle-bar"></span>
            </div>
          </button>

          <div className={`collapse navbar-collapse ${mobile && "show"}`}>
            <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link to="/" className="nav-link">
                Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">
                  Blog
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/pricing" className="nav-link">
                  MiSoulmate Plus
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/misoulmate-muslim-marriage/id6596806129?itscg=30200&itsct=apps_box_link&mttnsubad=6596806129"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link dark_btn"
                >
                  Download App
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Main;
