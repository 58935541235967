import React from 'react'
import Pricing from '../HomeMain/Pricing/Main'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Top from '../Pages/Menpricing/Top'
import First from '../Pages/Menpricing/First'


const Main = ({ brdcum }) => {

    return (
        <>
            {brdcum.b1 &&
                <Bredcrumb no={1} title="Our Pricing & Plans" paragraph="Explore our competitive pricing and subscription options. Choose the plan that best suits your needs." tag="Pricing" bgimg={BGImg} />
            }

            {brdcum.b2 &&
                <Bredcrumb no={2} title="Our Pricing & Plans" paragraph="Find the right plan for you with a range of flexible pricing options." tag="Pricing" bgimg={BGImg1} />
            }

            {brdcum.b5 &&
                <Bredcrumb no={5} title="Our Pricing & Plans" paragraph="Affordable plans to unlock exclusive features and premium experiences." tag="Pricing" bgimg={BGImg} />
            }

            {brdcum.b3 &&
                <Bredcrumb no={3} title="Our Pricing & Plans" paragraph="Choose from our variety of plans to get the best value and features." tag="Pricing" />
            }

            {brdcum.b4 &&
                <Bredcrumb no={4} title="Our Pricing & Plans" paragraph="Flexible pricing options designed to give you the best experience." tag="Pricing" bgimg={BGImg2} />
            }

            <Pricing />
            <Top />
            <First />
        </>
    )
}

export default Main
