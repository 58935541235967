import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/witnesses_in_sunni_nikah.png"; // Updated image

function WhatIsTheRoleOfWitnessesInSunniNikah() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>What is the Role of Witnesses in Sunni Nikah? | Islamic Marriage Insights</title>
                <meta name="description" content="Learn about the crucial role of witnesses in Sunni Nikah, their responsibilities, and significance in ensuring a valid Islamic marriage contract." />
                <meta name="keywords" content="Witnesses in Sunni Nikah, Islamic Marriage, Sunni Islam, Muslim Wedding, Nikah Ceremony" />
                <meta property="og:title" content="What is the Role of Witnesses in Sunni Nikah? | Islamic Marriage Insights" />
                <meta property="og:description" content="Understand the importance and role of witnesses in the Nikah process in Sunni Islam, a fundamental requirement for a valid marriage." />
                <meta property="og:image" content="/path/to/share-image.jpg" />
                <meta property="og:url" content="https://misoulmate.com/role-of-witnesses-in-sunni-nikah" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={1} 
                title="What is the Role of Witnesses in Sunni Nikah?" 
                paragraph="Explore the role of witnesses in Islamic marriage." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 4, 2024</span>
                        </div>
                        <div className="section_title">
                            <h2>What is the Role of Witnesses in Sunni Nikah?</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Witnesses in Sunni Nikah" />
                        </div>
                        <div className="info">
                            <p>In Sunni Islam, the presence of witnesses is a vital component of the Nikah (marriage) ceremony. The requirement for witnesses ensures the transparency, validity, and public acknowledgment of the marriage, aligning with the ethical and social principles of Islam.</p>

                            <h3>Legal and Religious Significance</h3>
                            <p>The role of witnesses in a Sunni Nikah is deeply rooted in Islamic jurisprudence. According to the Hanafi, Shafi'i, Maliki, and Hanbali schools of thought, the presence of at least two witnesses is mandatory for a Nikah to be considered valid. This requirement is derived from the hadiths and the teachings of the Quran, which emphasize the importance of public acknowledgment of marriage to prevent secret unions and ensure the protection of both parties' rights.</p>

                            <p>Witnesses serve as guarantors that the marriage contract is executed properly and that both parties consent to the union. Their presence helps prevent disputes regarding the legitimacy of the marriage in the future.</p>

                            <h3>Role and Responsibilities</h3>
                            <p>Witnesses in a Sunni Nikah have several key responsibilities:</p>
                            <ul>
                                <li>Ensuring that the marriage is conducted in accordance with Islamic principles.</li>
                                <li>Testifying to the consent of both parties, ensuring that neither the bride nor the groom is coerced into the marriage.</li>
                                <li>Confirming the proper declaration and acceptance (Ijab and Qabul) by both parties.</li>
                                <li>Providing testimony in the event of any disputes or misunderstandings related to the marriage.</li>
                            </ul>

                            <h3>Eligibility of Witnesses</h3>
                            <p>The witnesses must meet specific criteria to be considered valid:</p>
                            <ul>
                                <li>They must be Muslims who are mentally sound and have reached the age of maturity (baligh).</li>
                                <li>They should be trustworthy and of good character, as their testimony holds significant weight in Islamic law.</li>
                                <li>In most schools of thought, male witnesses are preferred, but some allow the presence of female witnesses as well, particularly in the absence of male witnesses.</li>
                            </ul>

                            <h3>Witnesses and Publicity</h3>
                            <p>One of the underlying purposes of requiring witnesses in a Sunni Nikah is to ensure the publicity of the marriage. This public acknowledgment serves to protect the rights of the bride, preventing secret or coerced marriages, and fostering a sense of accountability within the community. The Prophet Muhammad (PBUH) emphasized the importance of publicizing marriages, saying, "Announce this marriage and have it performed in the mosques" (Sunan Ibn Majah).</p>

                            <p>The public nature of the marriage also helps prevent disputes and allegations of illegitimacy, as the witnesses can testify to the marriage's validity if needed.</p>

                            <div className="quote_block">
                                <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                                <h2>"There is no marriage except with a guardian and two trustworthy witnesses."</h2>
                                <p><span className="name">Prophet Muhammad (PBUH),</span> Sunan Abu Dawood</p>
                            </div>

                            <p>In conclusion, the role of witnesses in Sunni Nikah is indispensable for ensuring the marriage's validity, legality, and public acknowledgment. Their presence upholds the ethical standards of Islamic marriage, protecting the rights and well-being of the spouses.</p>

                            <p>For those seeking a marriage partner within the Islamic framework, the MiSoulmate: Muslim Marriage App provides a unique platform. Our app features a 10-minute live matching session, helping individuals find their ideal Muslim partner while preventing fake profiles and ghosting. This innovative approach ensures that users can establish genuine connections based on mutual interest and Islamic values.</p>
                        </div>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 4, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Marriage,</span></li>
                                <li><span>Islam,</span></li>
                                <li><span>Nikah</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WhatIsTheRoleOfWitnessesInSunniNikah;
