import React from 'react';
import { Link } from 'react-router-dom';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/muslim_marriage_rules.png"; // Updated image name

function MuslimMarriageRules() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>Essential Rules of Muslim Marriage | Islamic Guidance</title>
                <meta name="description" content="Discover key rules of Muslim marriage, from consent to responsibilities, guided by Islamic principles. Learn how to build a harmonious marital life." />
                <meta name="keywords" content="Muslim Marriage Rules, Islamic Marriage, Marriage Consent in Islam, Rights in Muslim Marriage, Marriage Responsibilities" />
                <meta property="og:title" content="Essential Rules of Muslim Marriage | Islamic Guidance" />
                <meta property="og:description" content="Understand the essential rules governing Muslim marriage, from consent to marital duties, fostering a relationship based on Islamic values." />
                <meta property="og:image" content="/path/to/muslim_marriage_rules.jpg" />
                <meta property="og:url" content="https://misoulmate.com/muslim-marriage-rules" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <div className="bred_crumb" style={{ backgroundImage: `url(${BGImg})` }}>
                <div className="container">
                    <div className="bred_text">
                        <h1>Blog</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span>�</span></li>
                            <li><Link to="/blog">Blogs</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Guidance</span>
                            <span>October 25, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>Essential Rules of Muslim Marriage</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="Muslim Marriage Rules" />
                        </div>
                        <div className="info">
                            <p>Marriage in Islam is more than just a partnership; it is a commitment to mutual growth, responsibility, and respect, rooted in principles outlined by Islamic teachings. The Quran and Hadith offer clear guidance on the rights, duties, and responsibilities in a Muslim marriage, which ensures a stable foundation for both individuals and society. Muslim marriage is a union based on love, compassion, and a deep connection to faith, and the rules governing it are designed to reflect these values.</p>

                            <h3>The Significance of Consent in Muslim Marriage</h3>
                            <p>A core principle in Muslim marriage is the requirement of mutual consent. In Islam, marriage is invalid without the free will and acceptance of both the bride and the groom. This reflects the respect Islam holds for individual choice and agency, ensuring both partners enter the union willingly. The Prophet Muhammad (PBUH) emphasized that a marriage should never be forced, stating, �A previously married woman should not be married until her consent is sought�� (Sahih Bukhari).</p>

                            <h3>Roles and Responsibilities: Balancing Rights in Marriage</h3>
                            <p>Islamic marriage places equal importance on the rights and responsibilities of both spouses. While traditional roles often see the husband as the provider and the wife as the nurturer, each partner is encouraged to support and respect the other's contributions. The Quran states, "And women have rights similar to those over them in kindness" (Quran 2:228), underscoring the principle of fairness. In a healthy marriage, both partners work to build an environment of mutual respect, empathy, and understanding.</p>

                            <h3>Mutual Support and Compassion</h3>
                            <p>Marriage in Islam is intended to provide peace, stability, and support. The Quran describes spouses as "garments for each other" (Quran 2:187), symbolizing closeness, protection, and comfort. This metaphor encapsulates the importance of companionship and emotional intimacy in marriage, encouraging spouses to provide love, support, and kindness to each other, especially during challenging times.</p>

                            <h3>Financial Responsibilities and Partnership</h3>
                            <p>In Islamic marriage, the husband traditionally assumes financial responsibility, ensuring the welfare of the family. This is balanced by the wife�s role in managing the household and supporting the family�s well-being. However, modern interpretations encourage flexibility and partnership, with spouses discussing and sharing responsibilities to suit their individual strengths and circumstances. The Quran encourages consultation and mutual agreement (Shura) in decision-making, fostering a partnership based on cooperation and respect.</p>

                            <h3>Marriage as a Path to Spiritual Growth</h3>
                            <p>In Islam, marriage is seen as a path to spiritual fulfillment and a means to grow closer to Allah. Through the trials and joys of marriage, individuals learn patience, compassion, and the value of selflessness, all of which are crucial virtues in Islam. Marriage provides a supportive environment where both partners can nurture their faith, helping each other to fulfill religious obligations and stay true to Islamic values.</p>

                            <ul>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Consent is required from both partners, ensuring the marriage is based on mutual agreement.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Both spouses have equal rights and responsibilities, fostering a balanced relationship.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Marriage in Islam encourages emotional and spiritual support between partners.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Financial responsibilities are generally held by the husband, with flexibility for individual circumstances.</p></li>
                            </ul>

                            <div className="quote_block">
                                <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                                <h2>"Marriage is half of faith."</h2>
                                <p><span className="name">Prophet Muhammad (PBUH),</span> Sahih Muslim</p>
                            </div>

                            <p>Muslim marriage rules emphasize consent, mutual respect, and shared responsibilities. These rules are a guide to building a peaceful, compassionate, and supportive partnership, allowing each spouse to grow in faith and contribute positively to family and society. In fulfilling the duties within marriage, Muslims not only find personal satisfaction but also earn the pleasure of Allah, as marriage becomes a pathway to both worldly and spiritual success.</p>

                            <div className="blog_authore">
                                <div className="authore_info">
                                    <div className="avtar">
                                        <img src={BlogD1} alt="Author" />
                                    </div>
                                    <div className="text">
                                        <h3>By: Ayesha Khan</h3>
                                        <span>October 25, 2024</span>
                                    </div>
                                </div>
                                <div className="social_media">
                                    <ul>
                                        <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                        <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                        <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                        <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="blog_tags">
                                <ul>
                                    <li className="tags"><p>Tags:</p></li>
                                    <li><span>Marriage,</span></li>
                                    <li><span>Islam,</span></li>
                                    <li><span>Faith</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MuslimMarriageRules;
