import React from 'react';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png";  // Default background image
import Onoff from '../Menfaq/Onoff';
import First from '../Menfaq/First';

const Main = () => {

    return (
        <>
            {/* Always display the breadcrumb with the default image */}
            <Bredcrumb 
                no={1} 
                title="Have questions?" 
                paragraph="Find answers to common questions about our services." 
                tag="FAQ" 
                bgimg={BGImg} 
            />

            {/* FAQ Sections */}
            <Onoff />
            <First />
        </>
    );
}

export default Main;
