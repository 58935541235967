import React, { useState, useEffect } from 'react';

function Typewriter({ words, typingSpeed = 150, deletingSpeed = 50, pause = 1000 }) {
    const [displayText, setDisplayText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        let timer;
        const handleTyping = () => {
            const fullText = words[loopNum % words.length];

            if (isDeleting) {
                setDisplayText(fullText.substring(0, charIndex - 1));
                setCharIndex(charIndex - 1);
                timer = setTimeout(handleTyping, deletingSpeed);
            } else {
                setDisplayText(fullText.substring(0, charIndex + 1));
                setCharIndex(charIndex + 1);
                timer = setTimeout(handleTyping, typingSpeed);
            }

            if (!isDeleting && charIndex === fullText.length) {
                setTimeout(() => setIsDeleting(true), pause);
            } else if (isDeleting && charIndex === 0) {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
            }
        };

        timer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timer);
    }, [charIndex, isDeleting, loopNum, words, typingSpeed, deletingSpeed, pause]);

    return <span>{displayText}</span>;
}

export default Typewriter;
