import React from 'react';
import { Link } from 'react-router-dom';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png"; // Default background image
import Icon from "../../../assets/images/quote_icon.png";
import BlogD1 from "../../../assets/images/editor_ayesha_khan.png";
import { Helmet } from "react-helmet"; // For SEO
import MainPic from "../../../assets/images/what_is_nikkah.png";

function WhatIsNikkah() {
    return (
        <>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>What is Nikkah? | Islamic Insights</title>
                <meta name="description" content="Discover the meaning and significance of Nikkah, the Islamic marriage contract, which symbolizes faith and commitment in Islam. Learn about its importance in fostering love and respect between partners." />
                <meta name="keywords" content="Nikkah, Islamic Marriage, Muslim Wedding, Marriage in Islam, Faith in Islam" />
                <meta property="og:title" content="What is Nikkah? | Islamic Insights" />
                <meta property="og:description" content="Explore the Nikkah, the Islamic marriage ceremony, and understand its role in creating a bond of mutual love, respect, and faith in Islam." />
                <meta property="og:image" content="/path/to/what_is_nikkah.png" />
                <meta property="og:url" content="https://misoulmate.com/what-is-nikkah" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Breadcrumb */}
            <Bredcrumb 
                no={86} 
                title="What is Nikkah?" 
                paragraph="An insight into the Nikkah in Islam." 
                tag="Islamic Insights" 
                bgimg={BGImg} 
            />

            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="review">
                            <span>Islamic Insights</span>
                            <span>November 1, 2024</span> {/* Updated date */}
                        </div>
                        <div className="section_title">
                            <h2>What is Nikkah?</h2>
                        </div>
                        <div className="main_img">
                            <img src={MainPic} alt="What is Nikkah" />
                        </div>
                        <div className="info">
                            <p>The Nikkah, an essential part of Islamic tradition, represents the formal marriage contract in Islam, binding two individuals together under a divine and lawful framework. Far beyond a simple legal agreement, the Nikkah is a commitment of faith and love, offering both partners a shared path rooted in respect, responsibility, and dedication to Allah.</p>

                            <p>This ceremony is guided by the Quran and Hadith, which emphasize mutual respect, protection, and the roles that both spouses play in nurturing a balanced relationship. The Nikkah encourages Muslims to grow together in faith and form a loving household grounded in Islamic principles.</p>

                            <h3>Elements of the Nikkah Ceremony</h3>
                            <p>The Nikkah ceremony is distinguished by its simplicity and focus on consent, fairness, and responsibility. This process includes:</p>
                            <ul>
                                <li><strong>Mutual Consent:</strong> Islam requires that both individuals freely consent to the marriage, reinforcing the importance of personal choice and dignity.</li>
                                <li><strong>Mahr (Dowry):</strong> The groom provides a gift, known as the mahr, which symbolizes his commitment and respect for his future spouse.</li>
                                <li><strong>Witnesses:</strong> Two Muslim witnesses must be present to validate the marriage, ensuring transparency and accountability.</li>
                                <li><strong>Marriage Contract:</strong> A written agreement, signed by both parties, outlines their roles and expectations, setting a foundation of mutual respect and shared responsibility.</li>
                            </ul>

                            <h3>The Spiritual and Social Role of Nikkah</h3>
                            <p>Marriage in Islam transcends legal obligations, serving as both an act of worship and a social institution. The Quran describes spouses as �garments for each other� (Quran 2:187), symbolizing mutual protection and intimacy. Through marriage, individuals find tranquility and support in each other, working together to strengthen their faith and fulfill their spiritual obligations.</p>

                            <p>The Nikkah forms the cornerstone of family and community in Islamic society. It establishes an environment where love, patience, and forgiveness are fostered, promoting a nurturing space for raising future generations.</p>

                            <h3>Nikkah and Modern Muslim Matchmaking</h3>
                            <p>In today�s digital age, traditional matchmaking has evolved, with tools like <strong>MiSoulmate: Muslim Marriage App</strong> bridging the gap between tradition and technology. MiSoulmate offers unique features, including a 10-minute live matching session that allows users to gauge compatibility quickly. This approach prevents fake profiles and ghosting, providing a meaningful way to find a serious partner who shares similar values and goals.</p>

                            <h3>Significance of Mahr in Nikkah</h3>
                            <p>The mahr is a symbolic gift, expressing the groom�s dedication and responsibility towards his future spouse. This gift, which can range from money to other valuable items, reflects the importance of treating one�s spouse with respect and fairness.</p>

                            <h3>Conclusion: A Lifelong Bond</h3>
                            <p>The Nikkah is not merely a ceremony but a divine and social commitment that shapes the journey of two individuals. It offers a platform for spiritual growth, companionship, and the development of a family rooted in faith. By following the principles of Nikkah, Muslims embark on a path that fulfills their obligations to Allah, while contributing to a stable, loving society.</p>

                            <ul>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> Nikkah provides a lawful, respectful bond that supports faith and family.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> It encourages personal and spiritual growth within a structured framework.</p></li>
                                <li><p><span className="icon"><i className="icofont-check-circled"></i></span> The process safeguards moral values, promoting respect and dignity.</p></li>
                            </ul>
                        </div>

                        <div className="quote_block">
                            <span className="q_icon"><img src={Icon} alt="quote icon" /></span>
                            <h2>"Marriage completes half of one's faith."</h2>
                            <p><span className="name">Prophet Muhammad (PBUH),</span> Sahih Muslim</p>
                        </div>

                        <p>Through the Nikkah, Islam offers a path toward personal and social fulfillment. It unites individuals in faith, companionship, and commitment, emphasizing harmony, trust, and responsibility. By adhering to these principles, Muslims can enjoy a marriage that strengthens their connection to Allah and to each other.</p>

                        <div className="blog_authore">
                            <div className="authore_info">
                                <div className="avtar">
                                    <img src={BlogD1} alt="Author" />
                                </div>
                                <div className="text">
                                    <h3>By: Ayesha Khan</h3>
                                    <span>November 1, 2024</span>
                                </div>
                            </div>
                            <div className="social_media">
                                <ul>
                                    <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="blog_tags">
                            <ul>
                                <li className="tags"><p>Tags:</p></li>
                                <li><span>Nikkah,</span></li>
                                <li><span>Marriage in Islam,</span></li>
                                <li><span>Faith</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WhatIsNikkah;
