import React from 'react';
import { Link } from 'react-router-dom';

function AllBlog() {
    const stories = [
        {
            imgSrc: "/assets/images/halal_vs_traditional_dating.png",
            time: "October 1st, 2024",
            title: "Why Marriage is Good in Islam",
            content: "Explore the significance of marriage in Islam and its benefits for both individuals and society...",
            link: "/why-marriage-is-good-in-islam"
        },
        {
            imgSrc: "/assets/images/good_looking_muslim_couple.png",
            time: "October 1st, 2024",
            title: "Top 5 Reasons why Muslim Women Choose Halal Dating Apps",
            content: "Explore the reasons Muslim women opt for halal dating and how it reflects Islamic values...",
            link: "/top-5-reasons-muslima-women-choose-halal-dating"
        },
        {
            imgSrc: "/assets/images/halal_dating_apps_islamic_values.png",
            time: "October 2nd, 2024",
            title: "How to Keep Your Relationship Halal While Dating Online",
            content: "Learn how to maintain a halal relationship while dating online, with tips on following Shariah law...",
            link: "/how-to-keep-your-relationship-halal-while-dating-online"
        },
        {
            imgSrc: "/assets/images/empowered_muslima_using_dating_app.png",
            time: "October 3rd, 2024",
            title: "Empowering Muslim Women: How Muslimas are Redefining Marriage in the Digital Age",
            content: "Discover how Muslim women are taking control of their future and redefining marriage...",
            link: "/empowering-muslim-women-redefining-marriage"
        },
        {
            imgSrc: "/assets/images/muslima_live_marriage.png",
            time: "October 4th, 2024",
            title: "Live Marriage: The Future of Muslim Dating Apps",
            content: "Explore how live marriage features are revolutionizing Muslim dating apps...",
            link: "/live-marriage-future-of-dating-apps"
        },
        {
            imgSrc: "/assets/images/muslima_online_marriage_tips.png",
            time: "October 5th, 2024",
            title: "10 Essential Tips for Muslimas Looking for Marriage Online",
            content: "Discover 10 essential tips for Muslimas seeking marriage online, with insights from MiSoulmate...",
            link: "/muslimas-online-marriage-tips"
        },
        {
            imgSrc: "/assets/images/islamic_nikkah.png",
            time: "October 6th, 2024",
            title: "What Is a Nikkah? Understanding the Islamic Marriage Contract",
            content: "Explore the significance of Nikkah in Islam and how MiSoulmate supports Shariah-compliant marriages...",
            link: "/what-is-nikkah"
        },
        {
            imgSrc: "/assets/images/nikkah_online_match.png",
            time: "October 7th, 2024",
            title: "How to Find Your Perfect Nikkah Match Online",
            content: "Learn how MiSoulmate helps you find your perfect Nikkah match online using live matching features...",
            link: "/find-perfect-nikkah-match-online"
        },
        {
            imgSrc: "/assets/images/nikkah_technology.png",
            time: "October 8th, 2024",
            title: "Nikkah in Modern Times: How Technology Is Changing Traditional Marriages",
            content: "Learn how technology is reshaping Nikkah ceremonies and how MiSoulmate bridges the gap...",
            link: "/nikkah-in-modern-times"
        },
        {
            imgSrc: "/assets/images/preparing_nikkah.png",
            time: "October 9th, 2024",
            title: "Preparing for Nikkah: Steps to Take Before the Big Day",
            content: "Get tips on preparing for Nikkah, with guidance from MiSoulmate on how to stay focused...",
            link: "/preparing-for-nikkah"
        },
        {
            imgSrc: "/assets/images/busy_professionals_nikkah.png",
            time: "October 10th, 2024",
            title: "Nikkah Tips for Busy Professionals: How to Find Love Online",
            content: "Get practical tips for busy professionals on how to find love online through halal dating apps...",
            link: "/nikkah-tips-for-busy-professionals"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_easy.png",
            time: "October 11th, 2024",
            title: "Muslim Marriage Made Easy: Finding Your Soulmate Online",
            content: "Explore how Muslim marriage apps like MiSoulmate make it easy to find your soulmate online...",
            link: "/muslim-marriage-made-easy"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_future.png",
            time: "October 12th, 2024",
            title: "Why Muslim Marriage Apps Are the Future of Finding Love",
            content: "Discover why Muslim marriage apps like MiSoulmate are the future of finding love...",
            link: "/why-muslim-marriage-apps-are-the-future"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_expectations.png",
            time: "October 13th, 2024",
            title: "Muslim Marriage Expectations: What You Need to Know",
            content: "Learn about common Muslim marriage expectations and how MiSoulmate helps you meet them...",
            link: "/muslim-marriage-expectations"
        },
        {
            imgSrc: "/assets/images/muslim_marriage_partner_qualities.png",
            time: "October 14th, 2024",
            title: "Top 10 Qualities to Look for in a Muslim Marriage Partner",
            content: "Explore the top 10 qualities to seek in a Muslim spouse, and how MiSoulmate helps you find someone...",
            link: "/qualities-in-a-muslim-marriage-partner"
        },
        {
            imgSrc: "/assets/images/halal_dating_guide.png",
            time: "October 15th, 2024",
            title: "What Is Halal Dating? A Complete Guide for Muslims",
            content: "Understand what halal dating means, how it complies with Shariah law, and how MiSoulmate provides...",
            link: "/what-is-halal-dating"
        },
        {
            imgSrc: "/assets/images/halal_dating_apps_islamic_values.png",
            time: "October 16th, 2024",
            title: "How Halal Dating Apps Help You Stay True to Islamic Values",
            content: "Learn how halal dating apps like MiSoulmate help Muslims find love while staying true to Islamic values...",
            link: "/halal-dating-apps-and-islamic-values"
        },
        {
            imgSrc: "/assets/images/halal_online_dating.png",
            time: "October 17th, 2024",
            title: "How to Keep Your Relationship Halal While Dating Online",
            content: "Learn how to maintain a halal relationship while dating online, with tips on following Shariah law...",
            link: "/how-to-keep-your-relationship-halal-while-dating-online"
        },
        {
            imgSrc: "/assets/images/halal_vs_traditional_dating.png",
            time: "October 18th, 2024",
            title: "Halal Dating vs. Traditional Dating: Which Is Right for You?",
            content: "Explore the differences between halal dating and traditional dating, and learn how MiSoulmate provides...",
            link: "/halal-dating-vs-traditional-dating"
        },
        {
            imgSrc: "/assets/images/empowered_muslima_using_dating_app.png",
            time: "October 19th, 2024",
            title: "How Muslim Dating Apps Are Changing the Way Muslims Find Love",
            content: "Discover how Muslim dating apps like MiSoulmate are transforming the way Muslims find love...",
            link: "/muslim-dating-apps-finding-love"
        },
        {
            imgSrc: "/assets/images/live_marriage_future.png",
            time: "October 20th, 2024",
            title: "The Rise of Muslim Dating Apps in 2024: Why They Matter",
            content: "Understand the growing importance of Muslim dating apps in 2024 and how MiSoulmate leads the way...",
            link: "/live-marriage-future-of-dating-apps"
        },
        {
            imgSrc: "/assets/images/nikah_guide_for_muslims.png",
            time: "October 21st, 2024",
            title: "Nikah Guide for Muslims",
            content: "Explore the comprehensive guide to Nikah for Muslims and learn the steps to follow...",
            link: "/nikah-guide"
        }
    ];

    return (
        <>
            <section className="row_am latest_story blog_list_story" id="blog">
                <div className="container">
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box">
                                    <div className="story_img">
                                        <Link to={story.link}>
                                             <img src={story.imgSrc} alt={story.title}   style={{ width: "410px", height: "290px" }} />
                                        </Link>
                                        <span>{story.time}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.content}</p>
                                        <Link to={story.link}>READ MORE</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination_block">
                    <ul>
                        <li><Link to="../blog" className="prev"><i className="icofont-arrow-left"></i> Prev</Link></li>
                        <li><Link to="../blog" className="active">1</Link></li>
                        <li><Link to="../blog2">2</Link></li>
                        <li><Link to="../blog3">3</Link></li>
                        <li><Link to="../blog4">4</Link></li>
                        <li><Link to="../blog5">5</Link></li>
                        <li><Link to="../blog6">6</Link></li>
                        <li><Link to="../blog2" className="next">Next <i className="icofont-arrow-right"></i></Link></li>
                    </ul>
                    </div>

                </div>
            </section>
        </>
    );
}

export default AllBlog;
