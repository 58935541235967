import React from 'react';

function Review() {
    return (
        <>
            <section className="blog_detail_section">
                <div className="container">
                    <div className="blog_inner_pannel">
                        <div className="section_title">
                            <h2>Terms of Service</h2>
                        </div>
                        <div className="info">
                            <p>
                                Welcome to MiSoulmate’s Terms and Conditions of Use (these “Terms”). These Terms are a legally binding agreement between you and MiSoulmate Inc. (“MiSoulmate,” “we,” or “us”), a company incorporated in Ontario, Canada. Please read these Terms carefully before using our website, iOS, or Android applications (collectively the “Platform”).
                            </p>
                            <p>
                                By accessing or using MiSoulmate, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you should not use our Platform.
                            </p>

                            <h3>1. Summary of Key Points</h3>
                            <p>
                                To help you better understand the key points of these Terms, here’s a quick summary:
                            </p>
                            <ul>
                                <li><strong>Eligibility:</strong> You must be at least 18 years old to use MiSoulmate. The app is for adults only.</li>
                                <li><strong>User Content:</strong> You are responsible for the content you post. If you post anything inappropriate, we reserve the right to remove it at our discretion.</li>
                                <li><strong>Privacy:</strong> We care about your privacy. By using MiSoulmate, you agree to our <a href="/privacy-policy">Privacy Policy</a>.</li>
                                <li><strong>Paid Services:</strong> We offer paid subscriptions such as MiSoulmate Plus. These are subject to auto-renewal unless canceled.</li>
                                <li><strong>Termination:</strong> We reserve the right to suspend or terminate your account if you violate these Terms or our community guidelines.</li>
                            </ul>

                            <h3>2. Eligibility to Use MiSoulmate</h3>
                            <p>
                                MiSoulmate is intended for users who are 18 years of age or older. By using MiSoulmate, you represent and warrant that you are of legal age and have the authority to enter into a binding agreement. If you are under the age of 18, you are not permitted to use the Platform.
                            </p>
                            <p>
                                You are responsible for ensuring that your use of the Platform complies with all laws, rules, and regulations applicable to your location. MiSoulmate is not responsible for your failure to comply with such laws.
                            </p>

                            <h3>3. User Responsibilities</h3>
                            <p>
                                As a user of MiSoulmate, you agree to the following:
                            </p>
                            <ul>
                                <li>Provide accurate and current information about yourself during registration and maintain the accuracy of this information.</li>
                                <li>Keep your login credentials confidential and secure.</li>
                                <li>Do not impersonate another person or use another user’s account.</li>
                                <li>Do not use the Platform for any unlawful or prohibited activities.</li>
                            </ul>

                            <h3>4. User-Generated Content</h3>
                            <p>
                                MiSoulmate allows you to post, upload, or share content such as photos, messages, and other materials ("User Content"). By posting or uploading User Content, you grant MiSoulmate a worldwide, non-exclusive, royalty-free, and transferable license to use, display, reproduce, distribute, and modify your User Content for the purposes of providing and promoting the Platform.
                            </p>
                            <p>
                                You are solely responsible for the User Content you post. You agree not to post any content that:
                            </p>
                            <ul>
                                <li>Is abusive, threatening, harassing, defamatory, or discriminatory.</li>
                                <li>Contains nudity, sexual content, or other material that may offend community standards.</li>
                                <li>Promotes or engages in illegal activities.</li>
                                <li>Violates intellectual property rights, including copyrights or trademarks, of others.</li>
                            </ul>
                            <p>
                                MiSoulmate does not actively monitor User Content but reserves the right to remove any content that violates these Terms or our Community Guidelines.
                            </p>

                            <h3>5. Account Termination</h3>
                            <p>
                                MiSoulmate reserves the right to suspend or terminate your account at any time, for any reason, including but not limited to:
                            </p>
                            <ul>
                                <li>Violation of these Terms or our Community Guidelines.</li>
                                <li>Harassment or abuse of other users.</li>
                                <li>Posting inappropriate or illegal content.</li>
                                <li>Spamming or engaging in fraudulent activities.</li>
                            </ul>
                            <p>
                                If your account is terminated, you will lose access to your account, and any data or content associated with your account will be deleted.
                            </p>

                            <h3>6. Paid Services</h3>
                            <p>
                                MiSoulmate offers subscription-based services such as MiSoulmate Plus, which provides enhanced features to improve your user experience. Subscriptions are billed automatically through your App Store or Google Play account and will renew at the end of each subscription period unless canceled.
                            </p>
                            <h4>Auto-Renewal</h4>
                            <p>
                                Your MiSoulmate Plus subscription will automatically renew unless you cancel it before the renewal date. You may cancel your subscription at any time through the App Store, Google Play, or your account settings.
                            </p>
                            <h4>Refund Policy</h4>
                            <p>
                                Payments for subscriptions and in-app purchases are non-refundable, except where required by law. If you believe you have been charged in error, please contact our support team at support@misoulmate.com.
                            </p>

                            <h3>7. Privacy and Data Protection</h3>
                            <p>
                                Your privacy is important to us. MiSoulmate collects, processes, and stores personal data in accordance with our <a href="/privacy-policy">Privacy Policy</a>. By using MiSoulmate, you consent to the collection and use of your personal data as described in the Privacy Policy.
                            </p>

                            <h3>8. Data Deletion</h3>
                            <p>
                                You have the right to delete your MiSoulmate account at any time. Once your account is deleted, all associated data, including your profile, matches, and messages, will be removed from our servers within 3 days, except for data we are legally required to retain.
                            </p>

                            <h3>9. Intellectual Property</h3>
                            <p>
                                All content available on MiSoulmate, including but not limited to text, graphics, logos, icons, images, and software, is the property of MiSoulmate or its licensors and is protected by copyright, trademark, and other intellectual property laws.
                            </p>
                            <p>
                                You may not reproduce, distribute, modify, or create derivative works from any content on the Platform without prior written consent from MiSoulmate.
                            </p>

                            <h3>10. Disclaimer of Warranties</h3>
                            <p>
                                MiSoulmate is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the Platform, including but not limited to the availability, accuracy, reliability, or performance of the Platform.
                            </p>
                            <p>
                                We do not warrant that MiSoulmate will be error-free, uninterrupted, or free from viruses or other harmful components.
                            </p>

                            <h3>11. Limitation of Liability</h3>
                            <p>
                                To the maximum extent permitted by law, MiSoulmate will not be liable for any damages arising from your use or inability to use the Platform, including but not limited to direct, indirect, incidental, punitive, and consequential damages.
                            </p>
                            <p>
                                In jurisdictions that do not allow the exclusion of liability for certain damages, our liability will be limited to the extent permitted by law.
                            </p>

                            <h3>12. Governing Law and Dispute Resolution</h3>
                            <p>
                                These Terms and any disputes arising out of or in connection with them are governed by the laws of Ontario, Canada. Any disputes will be resolved in the courts of Ontario, and you consent to the exclusive jurisdiction of these courts.
                            </p>

                            <h3>13. Modifications to the Terms</h3>
                            <p>
                                MiSoulmate reserves the right to modify these Terms at any time. If we make material changes, we will notify you by updating the "Last Updated" date at the top of these Terms and providing additional notice as necessary. Your continued use of MiSoulmate after the effective date of the revised Terms constitutes your acceptance of the changes.
                            </p>

                            <h3>14. Contact Information</h3>
                            <p>
                                If you have any questions or concerns about these Terms, please contact us at support@misoulmate.com.
                            </p>

                            <div className="quote_block">
                                <h2>"Your privacy, safety, and experience are important to us. We continuously work to provide a secure platform for our users."</h2>
                                <p><span className="name">MiSoulmate Inc.,</span> Terms and Conditions Team</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Review;
