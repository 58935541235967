import React, { useState } from 'react'

function Top() {

    const [activeFaq, setActiveFaq] = useState({});

    const faqs = [
        {
            id: 1,
            question: 'How can I pay?',
            answer: 'You can pay for your subscription using several payment methods, including credit or debit cards, and mobile payment systems (like Apple Pay or Google Pay). All payments are processed through the App Store (iOS) or Google Play Store (Android). These platforms will charge your preferred payment method at the time of purchase and for any subscription renewals unless you cancel before the renewal date.'
        },
        {
            id: 2,
            question: 'How do I set up an account?',
            answer: 'To set up an account, download the app from the App Store or Google Play Store, open the app, and follow the registration steps. You will need to provide basic information such as your email address, phone number, and a password. Once registered, you can subscribe to premium services directly within the app by navigating to the subscription options.'
        },
        {
            id: 3,
            question: 'What is the process to get a refund?',
            answer: 'Refunds for in-app purchases, including subscriptions, are handled through the App Store (for iOS users) or Google Play Store (for Android users). Each platform has its own refund policy. You can request a refund by going to your app store account settings, selecting "Purchase History," and following the steps to request a refund for your purchase.'
        },
        {
            id: 4,
            question: 'Can I cancel my subscription anytime?',
            answer: 'Yes, you can cancel your subscription at any time by going to the settings on your device, navigating to your App Store or Google Play Store account, and managing your subscriptions. Your access to premium features will continue until the end of the current billing cycle, but your subscription will not auto-renew after cancellation.'
        },
        {
            id: 5,
            question: 'Can I get customization or bug support for my subscription?',
            answer: 'If you experience any issues with the app or your subscription, you can contact our support team through the app or via email. While we do not provide customization support for individual users, our team can help troubleshoot bugs and other technical issues related to your subscription or app performance.'
        },
        {
            id: 6,
            question: 'How do I change my subscription plan?',
            answer: 'To change your subscription plan, go to the subscription management section in your App Store or Google Play account. From there, you can upgrade or downgrade your plan. The changes will take effect at the start of your next billing cycle.'
        },
        {
            id: 7,
            question: 'What happens if my payment fails?',
            answer: 'If your payment fails, your subscription will not be renewed, and you may lose access to premium features. You can update your payment information in your App Store or Google Play Store account to avoid any interruptions in service.'
        }
    ];

    const handleToggle = (id) => {
        setActiveFaq({ ...activeFaq, [id]: !activeFaq[id] });
    }

    return (
        <>
            <section className="row_am faq_section">
                <div className="container">
                    <div className="faq_panel">
                        <div className="accordion" id="accordionExample">
                            {faqs.map((faq) => (
                                <div className="card" data-aos="fade-up" key={faq.id}>
                                    <div className="card-header" id={`heading${faq.id}`}>
                                        <h2 className="mb-0">
                                            <button type="button" className={`btn btn-link ${activeFaq[faq.id] && "active"}`} onClick={() => handleToggle(faq.id)} data-toggle="collapse" data-target={`#collapse${faq.id}`}>
                                                {activeFaq[faq.id] ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}
                                                {faq.question}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id={`collapse${faq.id}`} className={`collapse ${activeFaq[faq.id] && "show"}`} aria-labelledby={`heading${faq.id}`} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>{faq.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Top;
