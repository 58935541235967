import React, { useState } from 'react';

function Onoff() {
    const [activeFaq, setActiveFaq] = useState({
        a: true,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false
    });

    const toggleFaq = (faq) => {
        setActiveFaq(prevState => {
            const newState = {};
            Object.keys(prevState).forEach(key => {
                newState[key] = key === faq ? !prevState[key] : false;
            });
            return newState;
        });
    };

    return (
        <section className="row_am faq_section">
            <div className="container">
                <div className="faq_panel">
                    <div className="accordion" id="accordionExample">
                        {/* How can I pay? */}
                        <div className="card" data-aos="fade-up">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link ${activeFaq.a ? "" : "collapsed"}`} onClick={() => toggleFaq('a')} data-toggle="collapse" data-target="#collapseOne">
                                        {activeFaq.a ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}How can I pay for a subscription or in-app purchases?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" className={`collapse ${activeFaq.a ? "show" : ""}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>You can pay for your subscription or in-app purchases using a credit or debit card through the App Store (iOS) or Google Play Store (Android). We also support payment via PayPal where applicable. All payments are secure, and you can manage your subscription from the respective app store’s settings.</p>
                                </div>
                            </div>
                        </div>

                        {/* How to setup an account? */}
                        <div className="card" data-aos="fade-up">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link ${activeFaq.b ? "" : "collapsed"}`} onClick={() => toggleFaq('b')} data-toggle="collapse" data-target="#collapseTwo">
                                        {activeFaq.b ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}How do I set up an account?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" className={`collapse ${activeFaq.b ? "show" : ""}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>Setting up an account is simple! Download the MiSoulmate app, open it, and click on "Sign Up". Enter your email address or phone number, create a password, and fill in basic details such as your name, gender, and preferences. After verifying your email or phone, your account will be ready to use!</p>
                                </div>
                            </div>
                        </div>

                        {/* Refund process */}
                        <div className="card" data-aos="fade-up">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link  ${activeFaq.c ? "" : "collapsed"}`} onClick={() => toggleFaq('c')} data-toggle="collapse" data-target="#collapseThree">
                                        {activeFaq.c ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}What is the process to get a refund?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" className={`collapse ${activeFaq.c ? "show" : ""}`} aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>Refunds for MiSoulmate Plus subscriptions or in-app purchases are handled through the App Store or Google Play Store, depending on where you made the purchase. To request a refund, go to your purchase history on your respective app store, find the transaction, and click "Report a Problem." You can follow their instructions to submit a refund request.</p>
                                </div>
                            </div>
                        </div>

                        {/* Privacy of my data */}
                        <div className="card" data-aos="fade-up" >
                            <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link  ${activeFaq.d ? "" : "collapsed"}`} onClick={() => toggleFaq('d')} data-toggle="collapse" data-target="#collapseFour">
                                        {activeFaq.d ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}How does MiSoulmate protect my personal data?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" className={`collapse ${activeFaq.d ? "show" : ""}`} aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>We take your privacy seriously. MiSoulmate uses end-to-end encryption to protect your personal data during transmission. Additionally, your data is securely stored using Amazon Web Services (AWS), with regular security audits and updates to ensure it remains protected. For more information, check our <a href="/privacy-policy">Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </div>

                        {/* Can I delete my account? */}
                        <div className="card" data-aos="fade-up" >
                            <div className="card-header" id="headingFive">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link  ${activeFaq.e ? "" : "collapsed"}`} onClick={() => toggleFaq('e')} data-toggle="collapse"
                                        data-target="#collapseFive">{activeFaq.e ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}Can I delete my account permanently?
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseFive" className={`collapse ${activeFaq.e ? "show" : ""}`} aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>Yes, you can permanently delete your MiSoulmate account at any time. Navigate to your account settings, select "Delete Account," and follow the instructions. Once your account is deleted, all associated data, including your profile, matches, and messages, will be permanently removed from our servers.</p>
                                </div>
                            </div>
                        </div>

                        {/* What are the premium features? */}
                        <div className="card" data-aos="fade-up" >
                            <div className="card-header" id="headingSix">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link  ${activeFaq.f ? "" : "collapsed"}`} onClick={() => toggleFaq('f')} data-toggle="collapse"
                                        data-target="#collapseSix">{activeFaq.f ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}What features do I get with MiSoulmate Plus?
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseSix" className={`collapse ${activeFaq.f ? "show" : ""}`} aria-labelledby="headingSix" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>MiSoulmate Plus offers exclusive features such as unlimited likes, the ability to see who has liked your profile, advanced search filters, and access to premium matches. You also get a boost in profile visibility, helping you connect with more potential matches.</p>
                                </div>
                            </div>
                        </div>

                        {/* Contact customer support */}
                        <div className="card" data-aos="fade-up" >
                            <div className="card-header" id="headingSeven">
                                <h2 className="mb-0">
                                    <button type="button" className={`btn btn-link  ${activeFaq.g ? "" : "collapsed"}`} onClick={() => toggleFaq('g')} data-toggle="collapse"
                                        data-target="#collapseSeven">{activeFaq.g ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}How can I contact customer support for help?
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseSeven" className={`collapse ${activeFaq.g ? "show" : ""}`} aria-labelledby="headingSeven" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>If you need help or support, you can contact us by emailing <a href="mailto:support@misoulmate.com">support@misoulmate.com</a>. We aim to respond to all inquiries within 24-48 hours. For urgent matters, please use the contact form available in the app under the "Help & Support" section.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Onoff;
