import React from 'react';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png";  // Default background image
import Review from './TermsOfServiceText';

const Main = () => {
    return (
        <>
            {/* Always display the breadcrumb with the default image */}
            <Bredcrumb no={1} title="Terms of Service" tag="Terms of Service" bgimg={BGImg} />

            {/* Terms of Service content */}
            <Review />
        </>
    );
}

export default Main;
