import React from 'react';
import Bredcrumb from '../../Bredcrumb/Main';
import BGImg from "../../../assets/images/bread_crumb_bg.png";  // Default background image
import Review from './PrivacyPolicyText';

const Main = () => {
    return (
        <>
            {/* Always display the breadcrumb with the default image */}
            <Bredcrumb no={1} title="Privacy Policy" tag="Privacy Policy" bgimg={BGImg} />

            {/* Review section */}
            <Review />
        </>
    );
}

export default Main;
